import React, { lazy } from 'react'
import { TimeOffForm } from './TimeAttendance/TimeOffApplication/TimeOffForm'
import { TimeOffListing } from './TimeAttendance/TimeOffApplication/TimeOffListing'
import { TodoOvertimeDetails } from './Todo/TodoOvertimeDetail'

const MyMenu = lazy(() =>
  import('./MyMenu').then(module => ({
    default: module.MyMenu,
  }))
)

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  }))
)

const SystemAdminListing = lazy(() =>
  import('./SystemAdmin/SystemAdminListing').then(module => ({
    default: module.SystemAdminListing,
  }))
)

const UserListing = lazy(() =>
  import('./SystemAdmin/User/UserListing').then(module => ({
    default: module.UserListing,
  }))
)

const ResendEmailInvitation = lazy(() =>
  import('./SystemAdmin/User/ResendEmailInvitation').then(module => ({
    default: module.ResendEmailInvitation,
  }))
)

const UserDetail = lazy(() =>
  import('./SystemAdmin/User/UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)
const UserAccess = lazy(() =>
  import('./SystemAdmin/User/UserAccess').then(module => ({
    default: module.UserAccess,
  }))
)
const UserForm = lazy(() =>
  import('./SystemAdmin/User/UserForm').then(module => ({
    default: module.UserForm,
  }))
)

const AccessLevelListing = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelListing').then(module => ({
    default: module.AccessLevelListing,
  }))
)

const AccessLevelForm = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelForm').then(module => ({
    default: module.AccessLevelForm,
  }))
)

const AccessLevelDetail = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelDetail').then(module => ({
    default: module.AccessLevelDetail,
  }))
)
const EmployeeAppListing = lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppListing').then(
    module => ({
      default: module.EmployeeAppListing,
    })
  )
)

const EmployeeAppView = lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppView').then(module => ({
    default: module.EmployeeAppView,
  }))
)
const EmployeeAppForm = lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppForm').then(module => ({
    default: module.EmployeeAppForm,
  }))
)
const AutoNumberingSetupListing = lazy(() =>
  import('./SystemAdmin/AutoNumberingSetup/AutoNumberingSetupListing').then(
    module => ({
      default: module.AutoNumberingSetupListing,
    })
  )
)

const PayrollManagementListing = lazy(() =>
  import('./PayrollManagement/PayrollManagementListing').then(module => ({
    default: module.PayrollManagementListing,
  }))
)

const DigitalReportingManagementListing = lazy(() =>
  import('./DigitalReportingManagement/DigitalReportingManagementListing').then(
    module => ({
      default: module.DigitalReportingManagementListing,
    })
  )
)

const PayrollManagementEmployeeListing = lazy(() =>
  import('./PayrollManagement/PayrollManagementEmployeeListing').then(
    module => ({
      default: module.PayrollManagementEmployeeListing,
    })
  )
)
const MonthlyRemunerationEmployeeListing = lazy(() =>
  import(
    './PayrollManagement/MonthlyRemuneration/MonthlyRemunerationListing'
  ).then(module => ({
    default: module.MonthlyRemunerationEmployeeListing,
  }))
)
const EmployeeSalaryMenu = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeSalaryMenu').then(
    module => ({
      default: module.EmployeeSalaryMenu,
    })
  )
)
const EmployeeSalaryLogsListing = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeSalaryLogsListing').then(
    module => ({
      default: module.EmployeeSalaryLogsListing,
    })
  )
)
const EmployeeEnquiry = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeEnquiry').then(module => ({
    default: module.EmployeeEnquiry,
  }))
)
const EmployeeExcell = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeExcell').then(module => ({
    default: module.EmployeeExcell,
  }))
)
const EmployeeValidationExcell = lazy(() =>
  import('./PayrollManagement/EmployeeSalary/EmployeeValidationExcell').then(
    module => ({
      default: module.EmployeeValidationExcell,
    })
  )
)

const PayPeriodListing = lazy(() =>
  import('./PayrollManagement/PayPeriodListing').then(module => ({
    default: module.PayPeriodListing,
  }))
)

// const PersonnelListing = lazy(() =>
//   import('./PersonnelManagement/PersonnelEFile/PersonnelListing').then(
//     module => ({
//       default: module.PersonnelListing,
//     })
//   )
// )

// const StaffConfirmationListing = lazy(() =>
//   import(
//     './PersonnelManagement/StaffConfirmation/StaffConfirmationListing'
//   ).then(module => ({
//     default: module.StaffConfirmationListing,
//   }))
// )

const GLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/GLAccountMapping/GLAccountMappingListing'
  ).then(module => ({
    default: module.GLAccountMappingListing,
  }))
)

const ProjectMasterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectMaster/ProjectMasterListing'
  ).then(module => ({
    default: module.ProjectMasterListing,
  }))
)

const ProjectExpenseTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseType/ProjectExpenseTypeListing'
  ).then(module => ({
    default: module.ProjectExpenseTypeListing,
  }))
)

const GLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/GLAccountMapping/GLAccountMappingForm'
  ).then(module => ({
    default: module.GLAccountMappingForm,
  }))
)

const ProjectExpenseGLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/ProjectGLAccountMappingListing'
  ).then(module => ({
    default: module.ProjectGLAccountMappingListing,
  }))
)

const ProjectExpenseGLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/ProjectGLAccountMappingForm'
  ).then(module => ({
    default: module.ProjectGLAccountMappingForm,
  }))
)

const AutoNumberingSetupForm = lazy(() =>
  import('./SystemAdmin/AutoNumberingSetup/AutoNumberingSetupForm').then(
    module => ({
      default: module.AutoNumberingSetupForm,
    })
  )
)

const CompanyListing = lazy(() =>
  import('./SystemAdmin/Company/CompanyListing').then(module => ({
    default: module.CompanyListing,
  }))
)

const ModuleCompanyListing = lazy(() =>
  import('./ModuleCompanyListing').then(module => ({
    default: module.ModuleCompanyListing,
  }))
)

const CompanyInfoForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfoForm').then(module => ({
    default: module.CompanyInfoForm,
  }))
)

const CompanyAltAddressForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyAltAddressForm').then(module => ({
    default: module.CompanyAltAddressForm,
  }))
)

const CompanySummary = lazy(() =>
  import('./SystemAdmin/Company/CompanySummary').then(module => ({
    default: module.CompanySummary,
  }))
)

const ELetterTemplateListing = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/E-LetterTemplateListing').then(
    module => ({
      default: module.ELetterTemplateListing,
    })
  )
)
const DataChangesApprovalPolicyListing = lazy(() =>
  import(
    './SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyListing'
  ).then(module => ({
    default: module.DataChangesApprovalPolicyListing,
  }))
)
const DataChangesApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm'
  ).then(module => ({
    default: module.DataChangesApprovalPolicyForm,
  }))
)
const DataChangesPolicyListing = lazy(() =>
  import('./SystemAdmin/DataChangesPolicy/DataChangesPolicyListing').then(
    module => ({
      default: module.DataChangesPolicyListing,
    })
  )
)
const DataChangesPolicyForm = lazy(() =>
  import('./SystemAdmin/DataChangesPolicy/DataChangesPolicyForm').then(
    module => ({
      default: module.DataChangesPolicyForm,
    })
  )
)
const DataChangesPolicyView = lazy(() =>
  import('./SystemAdmin/DataChangesPolicy/DataChangesPolicyView').then(
    module => ({
      default: module.DataChangesPolicyView,
    })
  )
)
const ReportTemplateView = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateView').then(
    module => ({
      default: module.ReportTemplateView,
    })
  )
)
const ReportTemplateSetupListing = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateSetupListing').then(
    module => ({
      default: module.ReportTemplateSetupListing,
    })
  )
)

const StandardELetteringTemplateListing = lazy(() =>
  import(
    './SystemAdmin/E-LetterTemplateSetup/StandardELetteringTemplateListing'
  ).then(module => ({
    default: module.StandardELetteringTemplateListing,
  }))
)

const ReportTemplateForm = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm').then(
    module => ({
      default: module.ReportTemplateForm,
    })
  )
)

const ReportForPreview = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportForPreview').then(
    module => ({
      default: module.ReportForPreview,
    })
  )
)

const GeneralSettingListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  }))
)

const GeneralSettingListingV2 = lazy(() =>
  import('./SystemAdmin/GeneralSetting/GeneralSettingListingV2').then(
    module => ({
      default: module.GeneralSettingListingV2,
    })
  )
)

const PersonalManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/PersonalManagement'
  ).then(module => ({
    default: module.PersonalManagement,
  }))
)

const PayrollManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/PayrollManagement'
  ).then(module => ({
    default: module.PayrollManagement,
  }))
)

const LeaveManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/LeaveManagement'
  ).then(module => ({
    default: module.LeaveManagement,
  }))
)

const ClaimManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/ClaimManagement'
  ).then(module => ({
    default: module.ClaimManagement,
  }))
)

const ProjectExpenseManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/ProjectExpenseManagement'
  ).then(module => ({
    default: module.ProjectExpenseManagement,
  }))
)

const TimeAttendanceManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/TimeAttendanceManagement'
  ).then(module => ({
    default: module.TimeAttendanceManagement,
  }))
)

const RequisitionManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/RequisitionManagement'
  ).then(module => ({
    default: module.RequisitionManagement,
  }))
)

const Helpdesk = lazy(() =>
  import('./SystemAdmin/GeneralSetting/CommonSettingRoute/Helpdesk').then(
    module => ({
      default: module.Helpdesk,
    })
  )
)

const HelpdeskSubjectListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectListing'
  ).then(module => ({
    default: module.HelpdeskSubjectListing,
  }))
)

const HelpdeskSubjectForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm'
  ).then(module => ({
    default: module.HelpdeskSubjectForm,
  }))
)

const HelpdeskSubjectDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectDetails'
  ).then(module => ({
    default: module.HelpdeskSubjectDetails,
  }))
)

const RatingScaleListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RatingScale/RatingScaleListing').then(
    module => ({
      default: module.RatingScaleListing,
    })
  )
)

const RatingScaleForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RatingScale/RatingScaleForm').then(
    module => ({
      default: module.RatingScaleForm,
    })
  )
)

const FinalRatingTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FinalRatingTable/FinalRatingTableListing'
  ).then(module => ({
    default: module.FinalRatingTableListing,
  }))
)

const FinalRatingTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FinalRatingTable/FinalRatingTableForm'
  ).then(module => ({
    default: module.FinalRatingTableForm,
  }))
)

const JobSkillCategoryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/JobSkillCategory/JobSkillCategoryListing'
  ).then(module => ({
    default: module.JobSkillCategoryListing,
  }))
)

const CompetencyItemsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsListing'
  ).then(module => ({
    default: module.CompetencyItemsListing,
  }))
)

const CompetencyItemsDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsDetails'
  ).then(module => ({
    default: module.CompetencyItemsDetails,
  }))
)

const CompetencyItemsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsForm'
  ).then(module => ({
    default: module.CompetencyItemsForm,
  }))
)

const OtherAppraisalItemsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OtherAppraisalItems/OtherAppraisalItemsListing'
  ).then(module => ({
    default: module.OtherAppraisalItemsListing,
  }))
)

const OtherAppraisalItemsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OtherAppraisalItems/OtherAppraisalItemsForm'
  ).then(module => ({
    default: module.OtherAppraisalItemsForm,
  }))
)

const KpiAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateListing'
  ).then(module => ({
    default: module.KpiAppraisalTemplateListing,
  }))
)

const KpiAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateDetails'
  ).then(module => ({
    default: module.KpiAppraisalTemplateDetails,
  }))
)

const KpiAppraisalTemplateForms = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateForms'
  ).then(module => ({
    default: module.KpiAppraisalTemplateForms,
  }))
)

const SkillsAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateListing'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateListing,
  }))
)

const SkillsAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateDetails'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateDetails,
  }))
)

const SkillsAppraisalTemplateForms = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateForms'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateForms,
  }))
)

const OthersAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OthersAppraisalTemplate/OthersAppraisalTemplateListing'
  ).then(module => ({
    default: module.OthersAppraisalTemplateListing,
  }))
)

const OthersAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OthersAppraisalTemplate/OtherAppraisalTemplateDetails'
  ).then(module => ({
    default: module.OthersAppraisalTemplateDetails,
  }))
)

const ScaleRatingListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ScaleRating/ScaleRatingListing').then(
    module => ({
      default: module.ScaleRatingListing,
    })
  )
)

const ScaleRatingForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ScaleRating/ScaleRatingForm').then(
    module => ({
      default: module.ScaleRatingForm,
    })
  )
)

const RequisitionApprovalPolicyList = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicy'
  ).then(module => ({
    default: module.RequisitionApprovalPolicy,
  }))
)

const RequisitionApprovalPolicyDetail = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicyDetail'
  ).then(module => ({
    default: module.RequisitionApprovalPolicyDetail,
  }))
)

const RequisitionApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicyForm'
  ).then(module => ({
    default: module.RequisitionApprovalPolicyForm,
  }))
)

const CommonSettingRequisitionAPMenu = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionAPMenu'
  ).then(module => ({
    default: module.RequisitionAPMenu,
  }))
)
const CommonSettingRequisitionTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionType/RequisitionTypeListing'
  ).then(module => ({
    default: module.RequisitioniTypeListing,
  }))
)

const PersonnelParameterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/PersonnelParameterListing'
  ).then(module => ({
    default: module.PersonnelParameterListing,
  }))
)

const SalutationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/SalutationListing'
  ).then(module => ({
    default: module.SalutationListing,
  }))
)

const VaccinationTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/VaccinationTypeListing'
  ).then(module => ({
    default: module.VaccinationTypeListing,
  }))
)

const ExitCompanyReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/ExitCompanyReasonListing'
  ).then(module => ({
    default: module.ExitCompanyReasonListing,
  }))
)

const EducationLevelListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/EducationLevelListing'
  ).then(module => ({
    default: module.EducationLevelListing,
  }))
)

const MajorListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PersonnelParameter/MajorListing').then(
    module => ({
      default: module.MajorListing,
    })
  )
)

const GradeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PersonnelParameter/GradeListing').then(
    module => ({
      default: module.GradeListing,
    })
  )
)

const StaffCategoryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/StaffCategoryListing'
  ).then(module => ({
    default: module.StaffCategoryListing,
  }))
)

const SpecializationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/SpecializationListing'
  ).then(module => ({
    default: module.SpecializationListing,
  }))
)

const IndustryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/IndustryListing'
  ).then(module => ({
    default: module.IndustryListing,
  }))
)

const DocumentTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/DocumentTypeListing'
  ).then(module => ({
    default: module.DocumentTypeListing,
  }))
)

const JobGradeForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobGrade/JobGradeForm').then(module => ({
    default: module.JobGradeForm,
  }))
)

const SkillSetSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupListing'
  ).then(module => ({
    default: module.SkillSetSetupListing,
  }))
)

const SkillSetSetupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm').then(
    module => ({
      default: module.SkillSetSetupForm,
    })
  )
)
const NewLeaveRecordDetails = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveRecordDetails').then(module => ({
    default: module.NewLeaveRecordDetails,
  }))
)
const NewLeaveRecordDetailsForm = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveRecordDetailsForm').then(
    module => ({
      default: module.NewLeaveRecordDetailsForm,
    })
  )
)
const EntitlementLeaveTable = lazy(() =>
  import('./Subordinates/LeaveRecord/EntitlementLeaveTable').then(module => ({
    default: module.EntitlementLeaveTable,
  }))
)
const NewLeaveSubDetail = lazy(() =>
  import('./Subordinates/LeaveRecord/NewLeaveSubDetail').then(module => ({
    default: module.NewLeaveSubDetail,
  }))
)
const ReplacementLeaveSubTable = lazy(() =>
  import('./Subordinates/LeaveRecord/ReplacementLeaveSubTable').then(
    module => ({
      default: module.ReplacementLeaveSubTable,
    })
  )
)

const JobPositionListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobPosition/JobPositionListing').then(
    module => ({
      default: module.JobPositionListing,
    })
  )
)

const JobPositionForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobPosition/JobPositionForm').then(
    module => ({
      default: module.JobPositionForm,
    })
  )
)

const InsuranceSchemeSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/InsuranceSchemeSetup/InsuranceSchemeSetupListing'
  ).then(module => ({
    default: module.InsuranceSchemeSetupListing,
  }))
)

const RecruitmentSourceListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RecruitmentSource/RecruitmentSourceListing'
  ).then(module => ({
    default: module.RecruitmentSourceListing,
  }))
)

const UserDefineFieldListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/UserDefineField/UserDefineFieldListing'
  ).then(module => ({
    default: module.UserDefineFieldListing,
  }))
)

const PersonnelEFileSecurityPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelEFileSecurityPolicy/PersonnelEFileSecurityPolicyForm'
  ).then(module => ({
    default: module.PersonnelEFileSecurityPolicyForm,
  }))
)

const PayItemListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayItem/PayItemListing').then(
    module => ({
      default: module.PayItemListing,
    })
  )
)

const EnhancedPayrollItemForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayItem/EnhancedPayItemForm').then(
    module => ({
      default: module.EnhancedPayrollItemForm,
    })
  )
)

const AdvancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyListing'
  ).then(module => ({
    default: module.AdvancePolicyListing,
  }))
)

const AdvancePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyForm').then(
    module => ({
      default: module.AdvancePolicyForm,
    })
  )
)

const LHDNReportListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LHDNReport/LHDNReportListing').then(
    module => ({
      default: module.LHDNReportListing,
    })
  )
)

const LHDNReportForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LHDNReport/LHDNReportForm').then(
    module => ({
      default: module.LHDNReportForm,
    })
  )
)

const PayrollGLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollGLAccountMapping/GLAccountMappingListing'
  ).then(module => ({
    default: module.GLAccountMappingListing,
  }))
)

const PayrollGLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollGLAccountMapping/GLAccountMappingForm'
  ).then(module => ({
    default: module.GLAccountMappingForm,
  }))
)

const UserDefineEPFPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEPFPolicy/UserDefineEPFPolicyListing'
  ).then(module => ({
    default: module.UserDefineEPFPolicyListing,
  }))
)

const UserDefineEPFPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEPFPolicy/UserDefineEPFPolicyForm'
  ).then(module => ({
    default: module.UserDefineEPFPolicyForm,
  }))
)

const UserDefineEmployerEPFVEPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEmployerEPFVEPolicy/UserDefineEmployerEPFVEPolicyListing'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyListing,
  }))
)

const UserDefineEmployerEPFVEPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEmployerEPFVEPolicy/UserDefineEmployerEPFVEPolicyForm'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyForm,
  }))
)

const PayGroupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayGroup/PayGroupListing'
  ).then(module => ({
    default: module.PayGroupListing,
  }))
)

const PayrollCycleListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleListing').then(
    module => ({
      default: module.PayrollCycleListing,
    })
  )
)

const PayrollCycleForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleForm').then(
    module => ({
      default: module.PayrollCycleForm,
    })
  )
)

const PayrollCompanySetting = lazy(() =>
  import('./PayrollManagement/CompanySetting/PayrollCompanySetting').then(
    module => ({
      default: module.PayrollCompanySetting,
    })
  )
)
const PayrollInitiationPayCycle = lazy(() =>
  import('./PayrollManagement/PayrollInitiation/InitiationPayCycle').then(
    module => ({
      default: module.InitiationPayCycle,
    })
  )
)

const PayrollTemplateLibraryAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/TemplateLibraryAssignment/PayrollTemplateLibraryAssignment'
  ).then(module => ({
    default: module.PayrollTemplateLibraryAssignment,
  }))
)

const EnhancedPayrollTemplateLibraryAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/TemplateLibraryAssignment/EnhancedPayrollTemplateLibraryAssignment'
  ).then(module => ({
    default: module.EnhancedPayrollTemplateLibraryAssignment,
  }))
)

const PayrollSetting = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/PayrollSetting/PayrollSetting'
  ).then(module => ({
    default: module.PayrollSetting,
  }))
)

// const PayrollGLMappingAssignment = lazy(() =>
//   import(
//     './SystemAdmin/GeneralSetting/CompanySetting/PayrollGLMappingAssignment/GLMappingAssignmentForm'
//   ).then(module => ({
//     default: module.GLMappingAssignmentForm,
//   }))
// )

const BankListing = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/BankListing').then(
    module => ({
      default: module.BankListing,
    })
  )
)

const PayrollGLAccountMappingAssignment = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/GLMappingAssignment/GLMappingAssignmentForm'
  ).then(module => ({
    default: module.GLMappingAssignmentForm,
  }))
)

const BankForm = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/BankForm').then(module => ({
    default: module.BankForm,
  }))
)

const InterBankListing = lazy(() =>
  import('./PayrollManagement/CompanySetting/Bank/InterBankListing').then(
    module => ({
      default: module.InterBankListing,
    })
  )
)

const UserDefineEPFPolicyMatrixListing = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixListing'
  ).then(module => ({
    default: module.UserDefineEPFPolicyMatrixListing,
  }))
)

const UserDefineEPFPolicyMatrixForm = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixForm'
  ).then(module => ({
    default: module.UserDefineEPFPolicyMatrixForm,
  }))
)

const UserDefineEmployerEPFVEPolicyMatrixListing = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixListing'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyMatrixListing,
  }))
)

const UserDefineEmployerEPFVEPolicyMatrixForm = lazy(() =>
  import(
    './PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixForm'
  ).then(module => ({
    default: module.UserDefineEmployerEPFVEPolicyMatrixForm,
  }))
)

const LeaveReasonListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveReason/LeaveReasonListing').then(
    module => ({
      default: module.LeaveReasonListing,
    })
  )
)

const DiseaseCodeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/DiseaseCode/DiseaseCodeListing').then(
    module => ({
      default: module.DiseaseCodeListing,
    })
  )
)

const LeaveTypeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveType/LeaveTypeListing').then(
    module => ({
      default: module.LeaveTypeListing,
    })
  )
)

const LeavePolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyListing').then(
    module => ({
      default: module.LeavePolicyListing,
    })
  )
)

const LeavePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm').then(
    module => ({
      default: module.LeavePolicyForm,
    })
  )
)

const LeavePolicyDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyDetail').then(
    module => ({
      default: module.LeavePolicyDetail,
    })
  )
)

const LeaveRoundingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingListing'
  ).then(module => ({
    default: module.LeaveRoundingListing,
  }))
)

const LeaveRoundingForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm').then(
    module => ({
      default: module.LeaveRoundingForm,
    })
  )
)

const LeaveRoundingDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingDetail').then(
    module => ({
      default: module.LeaveRoundingDetail,
    })
  )
)

const LeaveApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicy'
  ).then(module => ({
    default: module.LeaveApprovalPolicy,
  }))
)

const LeaveApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm'
  ).then(module => ({
    default: module.LeaveApprovalPolicyForm,
  }))
)

const ClaimParameterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimParameterListing'
  ).then(module => ({
    default: module.ClaimParameterListing,
  }))
)

const VehicleTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/VehicleType/VehicleTypeListing'
  ).then(module => ({
    default: module.VehicleTypeListing,
  }))
)
const ClaimUserDefineFieldsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing'
  ).then(module => ({
    default: module.ClaimUserDefineFieldsListing,
  }))
)
const ClaimUserDefineFieldsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsForm'
  ).then(module => ({
    default: module.ClaimUserDefineFieldsForm,
  }))
)

const MileageLocationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageLocation/MileageLocationListing'
  ).then(module => ({
    default: module.MileageLocationListing,
  }))
)

const MileageTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableListing'
  ).then(module => ({
    default: module.MileageTableListing,
  }))
)

const MileageTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm'
  ).then(module => ({
    default: module.MileageTableForm,
  }))
)

const MealTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MealType/MealTypeListing'
  ).then(module => ({
    default: module.MealTypeListing,
  }))
)

const VenueListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/Venue/VenueListing'
  ).then(module => ({
    default: module.VenueListing,
  }))
)

const ClaimTypeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimType/ClaimTypeListing').then(
    module => ({
      default: module.ClaimTypeListing,
    })
  )
)

const ClaimPolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyListing').then(
    module => ({
      default: module.ClaimPolicyListing,
    })
  )
)

const ClaimPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm').then(
    module => ({
      default: module.ClaimPolicyForm,
    })
  )
)

const ClaimPolicyDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDetail').then(
    module => ({
      default: module.ClaimPolicyDetail,
    })
  )
)

const ClaimPolicyDailyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm').then(
    module => ({
      default: module.ClaimPolicyDailyForm,
    })
  )
)

const ClaimPolicyMealForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm').then(
    module => ({
      default: module.ClaimPolicyMealForm,
    })
  )
)

const ClaimApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicy'
  ).then(module => ({
    default: module.ClaimApprovalPolicy,
  }))
)

const ClaimApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm'
  ).then(module => ({
    default: module.ClaimApprovalPolicyForm,
  }))
)

const ShiftSetupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupListing').then(
    module => ({
      default: module.ShiftSetupListing,
    })
  )
)

const ShiftSetupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm').then(
    module => ({
      default: module.ShiftSetupForm,
    })
  )
)

const ShiftPatternListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternListing').then(
    module => ({
      default: module.ShiftPatternListing,
    })
  )
)

const ShiftPatternForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm').then(
    module => ({
      default: module.ShiftPatternForm,
    })
  )
)

const ShiftGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupListing').then(
    module => ({
      default: module.ShiftGroupListing,
    })
  )
)

const ShiftGroupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm').then(
    module => ({
      default: module.ShiftGroupForm,
    })
  )
)

const OvertimePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyListing'
  ).then(module => ({
    default: module.OvertimePolicyListing,
  }))
)

const OvertimePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm').then(
    module => ({
      default: module.OvertimePolicyForm,
    })
  )
)

const OvertimeClaimTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableListing'
  ).then(module => ({
    default: module.OvertimeClaimTableListing,
  }))
)

const OvertimeClaimTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm'
  ).then(module => ({
    default: module.OvertimeClaimTableForm,
  }))
)

const OvertimeClaimTableView = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableView'
  ).then(module => ({
    default: module.OvertimeClaimTableView,
  }))
)

const OvertimeReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeReasonCodes/OvertimeReasonListing'
  ).then(module => ({
    default: module.OvertimeReasonListing,
  }))
)

const OvertimeRoundingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingListing'
  ).then(module => ({
    default: module.OvertimeRoundingListing,
  }))
)

const OvertimeRoundingPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingForm'
  ).then(module => ({
    default: module.OvertimeRoundingPolicyForm,
  }))
)

const AllowanceDeductionRoundingPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyListing'
  ).then(module => ({
    default: module.AllowanceDeductionRoundingPolicy,
  }))
)

const AllowanceDeductionRoundingPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm'
  ).then(module => ({
    default: module.AllowanceDeductionRoundingPolicyForm,
  }))
)

const DailyAllowancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyListing'
  ).then(module => ({
    default: module.DailyAllowancePolicyListing,
  }))
)

const DailyAllowancePolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm'
  ).then(module => ({
    default: module.DailyAllowancePolicyForm,
  }))
)

const MonthlyAllowancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyListing'
  ).then(module => ({
    default: module.MonthlyAllowancePolicyListing,
  }))
)

const MonthlyAllowancePolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm'
  ).then(module => ({
    default: module.MonthlyAllowancePolicyForm,
  }))
)

const DeductionPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyListing'
  ).then(module => ({
    default: module.DeductionPolicyListing,
  }))
)

const DeductionPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm'
  ).then(module => ({
    default: module.DeductionPolicyForm,
  }))
)

const TimeOffPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyListing'
  ).then(module => ({
    default: module.TimeOffPolicyListing,
  }))
)

const TimeOffPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm').then(
    module => ({
      default: module.TimeOffPolicyForm,
    })
  )
)

const TimeOffReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TimeOffReasonCode/TimeOffReasonListing'
  ).then(module => ({
    default: module.TimeOffReasonListing,
  }))
)

const MobileCheckInListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInListing'
  ).then(module => ({
    default: module.MobileCheckInListing,
  }))
)

const MobileCheckInForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm'
  ).then(module => ({
    default: module.MobileCheckInForm,
  }))
)

const GenerateQR = lazy(() =>
  import('./SystemAdmin/GeneralSetting/MobileCheckInLocation/GenerateQR').then(
    module => ({
      default: module.GenerateQR,
    })
  )
)

const BeaconDeviceListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceListing'
  ).then(module => ({
    default: module.BeaconDeviceListing,
  }))
)

const BeaconDeviceForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm'
  ).then(module => ({
    default: module.BeaconDeviceForm,
  }))
)

const TMSAPMenu = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSAPMenu').then(
    module => ({
      default: module.TMSAPMenu,
    })
  )
)

const TMSApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy'
  ).then(module => ({
    default: module.TMSApprovalPolicy,
  }))
)

const TMSApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm'
  ).then(module => ({
    default: module.TMSApprovalPolicyForm,
  }))
)

const HomeLeave = lazy(() =>
  import('./EmployeeMyLeave/MyLeave').then(module => ({
    default: module.MyLeave,
  }))
)

const MyLeaveDetail = lazy(() =>
  import('./EmployeeMyLeave/MyLeaveDetail').then(module => ({
    default: module.MyLeaveDetail,
  }))
)

const NewMyLeave = lazy(() =>
  import('./EmployeeMyLeave/NewMyLeave').then(module => ({
    default: module.NewMyLeave,
  }))
)

const HomeLeaveForm = lazy(() =>
  import('./EmployeeMyLeave/MyLeaveForm').then(module => ({
    default: module.MyLeaveForm,
  }))
)

const NewMyLeaveForm = lazy(() =>
  import('./EmployeeMyLeave/NewMyLeaveForm').then(module => ({
    default: module.NewMyLeaveForm,
  }))
)

const EntitlementTable = lazy(() =>
  import('./EmployeeMyLeave/EntitlementTable').then(module => ({
    default: module.EntitlementTable,
  }))
)

const ReplacementLeaveTable = lazy(() =>
  import('./EmployeeMyLeave/ReplacementLeaveTable').then(module => ({
    default: module.ReplacementLeaveTable,
  }))
)

const ApprovalHistory = lazy(() =>
  import('./Todo/ApprovalHistory').then(module => ({
    default: module.ApprovalHistory,
  }))
)

const HomeClaim = lazy(() =>
  import('./EmployeeMyClaim/MyClaimListing').then(module => ({
    default: module.MyClaimListing,
  }))
)

const MyClaimForm = lazy(() =>
  import('./EmployeeMyClaim/MyClaimForm').then(module => ({
    default: module.MyClaimForm,
  }))
)

const MyClaimDetail = lazy(() =>
  import('./EmployeeMyClaim/MyClaimDetail').then(module => ({
    default: module.MyClaimDetail,
  }))
)

const MyClaimItemDetail = lazy(() =>
  import('./EmployeeMyClaim/MyClaimItemDetail').then(module => ({
    default: module.MyClaimItemDetail,
  }))
)

const MyClaimEntitlementTable = lazy(() =>
  import('./EmployeeMyClaim/MyClaimEntitlementTable').then(module => ({
    default: module.EntitlementTable,
  }))
)

const ClaimItemForm = lazy(() =>
  import('./EmployeeMyClaim/ClaimItemForm').then(module => ({
    default: module.ClaimItemForm,
  }))
)

const HomeRequisition = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionListing').then(module => ({
    default: module.MyRequisitionListing,
  }))
)

const HomeRequisitionForm = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionForm').then(module => ({
    default: module.MyRequisitionForm,
  }))
)

const MyRequisitionDetail = lazy(() =>
  import('./EmployeeMyRequisition/MyRequisitionDetail').then(module => ({
    default: module.MyRequisitionDetail,
  }))
)

const HomeDigitalDocument = lazy(() =>
  import('./DigitalDocument/DigitalDocumentView').then(module => ({
    default: module.DigitalDocumentView,
  }))
)

const HomeDigitalDocuments = lazy(() =>
  import('./DigitalDocument/EmployeeDocumentList').then(module => ({
    default: module.EmployeeDocumentList,
  }))
)

const HomeNotification = lazy(() =>
  import('./Notification/HomeNotification').then(module => ({
    default: module.HomeNotification,
  }))
)

const HomeNotificationDetail = lazy(() =>
  import('./Notification/HomeNotificationDetail').then(module => ({
    default: module.HomeNotificationDetail,
  }))
)
const HomeNotificationDataChangesDetail = lazy(() =>
  import('./Notification/HomeNotificationDataChangesDetail').then(module => ({
    default: module.HomeNotificationDataChangesDetail,
  }))
)
// const HomeTodo = lazy(() =>
//   import('./Todo/Todo').then(module => ({
//     default: module.Todo,
//   }))
// )
const TodoDetail = lazy(() =>
  import('./Todo/TodoDetail').then(module => ({
    default: module.TodoDetail,
  }))
)

const TodoClaimDetail = lazy(() =>
  import('./Todo/TodoClaimDetail').then(module => ({
    default: module.TodoClaimDetail,
  }))
)

const TodoRequisitionDetail = lazy(() =>
  import('./Todo/TodoRequisitionDetail').then(module => ({
    default: module.TodoRequisitionDetail,
  }))
)

const TodoRequisitionDetailV2 = lazy(() =>
  import('./Todo/ToDoRequisitionDetailV2').then(module => ({
    default: module.TodoRequisitionDetailV2,
  }))
)

const TodoProjectExpenseDetail = lazy(() =>
  import('./Todo/TodoProjectExpenseDetail').then(module => ({
    default: module.TodoProjectExpenseDetail,
  }))
)

const MySubordinatesListing = lazy(() =>
  import('./Subordinates/MySubordinatesListing').then(module => ({
    default: module.MySubordinatesListing,
  }))
)

const MySubordinatesSummary = lazy(() =>
  import('./Subordinates/MySubordinatesSummary').then(module => ({
    default: module.MySubordinatesSummary,
  }))
)

const MySubordinatesSummaryOlderVersion = lazy(() =>
  import('./Subordinates/MySubordinatesSummaryOlderVersion').then(module => ({
    default: module.MySubordinatesSummaryOlderVersion,
  }))
)

const EmployeeInfoDetails = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeInfoDetails').then(module => ({
    default: module.EmployeeInfoDetails,
  }))
)
const AssetAssignedDetails = lazy(() =>
  import('./Subordinates/AssetAssigned/AssetAssignedDetails').then(module => ({
    default: module.AssetAssignedDetails,
  }))
)
const LeaveRecordsDetails = lazy(() =>
  import('./Subordinates/LeaveRecord/LeaveRecordsDetails').then(module => ({
    default: module.LeaveRecordsDetails,
  }))
)

const ClaimRecordsDetails = lazy(() =>
  import('./Subordinates/ClaimRecords/ClaimRecordsDetails').then(module => ({
    default: module.ClaimRecordsDetails,
  }))
)

const CareerProgressionDetails = lazy(() =>
  import('./Subordinates/CareerProgression/CareerProgressionDetails').then(
    module => ({
      default: module.CareerProgressionDetails,
    })
  )
)
const RequisitionRecordsDetails = lazy(() =>
  import('./Subordinates/RequisitionRecords/RequisitionRecordsDetails').then(
    module => ({
      default: module.RequisitionRecordsDetails,
    })
  )
)

const EmployeeSubordinates = lazy(() =>
  import('./Subordinates/Subordinates/EmployeeSubordinates').then(module => ({
    default: module.EmployeeSubordinates,
  }))
)

const EmployeeInfo = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeInfo').then(module => ({
    default: module.EmployeeInfo,
  }))
)

const EmployeeJobInfoDetails = lazy(() =>
  import('./Subordinates/EmployeeInfo/EmployeeJobInfoDetails').then(module => ({
    default: module.EmployeeJobInfoDetails,
  }))
)

const SubordinatePersonalStatutoryDetails = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalStatInfo/PersonalStatDetails'
  ).then(module => ({
    default: module.SubordinatePersonalStatDetails,
  }))
)

const SubordinatePersonalTP3Details = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalTP3/PersonalTP3Details'
  ).then(module => ({
    default: module.SubordinatePersonalTP3Details,
  }))
)

const CareerProgression = lazy(() =>
  import('./CareerProgression/CareerProgression').then(module => ({
    default: module.CareerProgression,
  }))
)

const HomeBulletin = lazy(() =>
  import('./Bulletin/HomeBulletin').then(module => ({
    default: module.HomeBulletin,
  }))
)

const HomeCalendar = lazy(() =>
  import('./Calendar/HomeCalendar').then(module => ({
    default: module.HomeCalendar,
  }))
)

const GeneralDocument = lazy(() =>
  import('./DigitalDocument/GeneralDocument/GeneralDocumentListing').then(
    module => ({
      default: module.GeneralDocumentListing,
    })
  )
)

const PersonalDocument = lazy(() =>
  import('./DigitalDocument/PersonalDocument/PersonalDocumentListing').then(
    module => ({
      default: module.PersonalDocumentListing,
    })
  )
)

const PayslipListing = lazy(() =>
  import('./DigitalDocument/Payslip/PayslipListing').then(module => ({
    default: module.PayslipListing,
  }))
)

const EAFormListing = lazy(() =>
  import('./DigitalDocument/EAForm/EAFormListing').then(module => ({
    default: module.EAFormListing,
  }))
)

const ELetterListing = lazy(() =>
  import('./DigitalDocument/ELetter/ELetterListing').then(module => ({
    default: module.ELetterListing,
  }))
)

const ELetterView = lazy(() =>
  import('./DigitalDocument/ELetter/ELetterView').then(module => ({
    default: module.ELetterView,
  }))
)

const EAFormView = lazy(() =>
  import('./DigitalDocument/EAForm/EAFormView').then(module => ({
    default: module.EAFormView,
  }))
)

const PayslipView = lazy(() =>
  import('./DigitalDocument/Payslip/PaySlipView').then(module => ({
    default: module.PayslipView,
  }))
)
//// Time Attendance ////
const TimeAttendanceView = lazy(() =>
  import('./TimeAttendance/TimeAttendanceView').then(module => ({
    default: module.TimeAttendanceView,
  }))
)

const OvertimeListing = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeListing').then(
    module => ({
      default: module.OvertimeListing,
    })
  )
)

const OvertimeDetails = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeDetails').then(
    module => ({
      default: module.OvertimeDetails,
    })
  )
)

const TimeOffDetails = lazy(() =>
  import('./TimeAttendance/TimeOffApplication/TimeOffDetails').then(module => ({
    default: module.TimeOffDetails,
  }))
)

const ChangeShiftRequestDetails = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestDetails').then(
    module => ({
      default: module.ChangeShiftRequestDetails,
    })
  )
)

const OvertimeForm = lazy(() =>
  import('./TimeAttendance/OvertimeApplication/OvertimeForm').then(module => ({
    default: module.OvertimeForm,
  }))
)

const EmpAttendance = lazy(() =>
  import('./TimeAttendance/AttendanceAnalysis/AttendanceAnalysis').then(
    module => ({
      default: module.AttendanceAnalysis,
    })
  )
)

const EmpCheckIn = lazy(() =>
  import('./TimeAttendance/CheckIn/CheckIn').then(module => ({
    default: module.CheckIn,
  }))
)

const EmpCheckOut = lazy(() =>
  import('./TimeAttendance/CheckIn/CheckOut').then(module => ({
    default: module.CheckOut,
  }))
)

const MySubordinatesBatchOvertimeEntryListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryListing'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryListing,
  }))
)
const MySubordinatesBatchOvertimeEntryForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryForm'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryForm,
  }))
)
const MySubordinatesBatchOvertimeEntryView = lazy(() =>
  import(
    './Subordinates/TimeAttendance/BatchOvertimeEntry/MySubordinatesBatchOvertimeEntryView'
  ).then(module => ({
    default: module.MySubordinatesBatchOvertimeEntryView,
  }))
)

// const CheckInHistory = lazy(() =>
//   import('./TimeAttendance/CheckIn/History').then(module => ({
//     default: module.CheckInHistory,
//   }))
// )
const ChangeShiftRequestListing = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestListing').then(
    module => ({
      default: module.ChangeShiftRequestListing,
    })
  )
)

const ChangeShiftRequestForm = lazy(() =>
  import('./TimeAttendance/ChangeShiftRequest/ChangeShiftRequestForm').then(
    module => ({
      default: module.ChangeShiftRequestForm,
    })
  )
)

// const CheckInHistory = lazy(() =>
//   import('./TimeAttendance/CheckIn/History').then(module => ({
//     default: module.CheckInHistory,
//   }))
// )

//// Delegation ////

const DelegationListing = lazy(() =>
  import('./Delegation/DelegationListing').then(module => ({
    default: module.DelegationListing,
  }))
)

const DelegationForm = lazy(() =>
  import('./Delegation/DelegationForm').then(module => ({
    default: module.DelegationForm,
  }))
)

const DelegationView = lazy(() =>
  import('./Delegation/DelegationView').then(module => ({
    default: module.DelegationView,
  }))
)

const CheckInHistory = lazy(() =>
  import('./TimeAttendance/CheckIn/History').then(module => ({
    default: module.CheckInHistory,
  }))
)

const PersonalNav = lazy(() =>
  import('./PersonalProfile/PersonalNav').then(module => ({
    default: module.PersonalNav,
  }))
)

const EmploymentInfoNav = lazy(() =>
  import('./EmploymentInfo/EmploymentInfoNav').then(module => ({
    default: module.EmploymentInfoNav,
  }))
)

const DiaryListing = lazy(() =>
  import('./Diary/DiaryListing').then(module => ({
    default: module.DiaryListing,
  }))
)

const DiaryForm = lazy(() =>
  import('./Diary/DiaryForm').then(module => ({
    default: module.DiaryForm,
  }))
)

const PersonalInfoDetails = lazy(() =>
  import('./PersonalProfile/PersonalInfo/PersonalInfoDetails').then(module => ({
    default: module.PersonalInfoDetails,
  }))
)

const PersonalInfoDetailsForm = lazy(() =>
  import('./PersonalProfile/PersonalInfo/PersonalInfoDetailsForm').then(
    module => ({
      default: module.PersonalInfoDetailsForm,
    })
  )
)

const PersonalJobDetails = lazy(() =>
  import('./PersonalProfile/PersonalJobInfo/PersonalJobDetails').then(
    module => ({
      default: module.PersonalJobDetails,
    })
  )
)

const PersonalStatDetails = lazy(() =>
  import('./PersonalProfile/PersonalStatInfo/PersonalStatDetails').then(
    module => ({
      default: module.PersonalStatDetails,
    })
  )
)

const PersonalDependentDetails = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentDetails').then(
    module => ({
      default: module.PersonalDependentDetails,
    })
  )
)
const PersonalDependentView = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentView').then(
    module => ({
      default: module.PersonalDependentView,
    })
  )
)

const DependentForm = lazy(() =>
  import('./PersonalProfile/PersonalDependent/PersonalDependentForm').then(
    module => ({
      default: module.DependentForm,
    })
  )
)

const EducationList = lazy(() =>
  import('./Education/EducationList').then(module => ({
    default: module.EducationList,
  }))
)

const EducationForm = lazy(() =>
  import('./Education/EducationForm').then(module => ({
    default: module.EducationForm,
  }))
)

const CertificateListing = lazy(() =>
  import('./Certificate/CertificateListing').then(module => ({
    default: module.CertificateListing,
  }))
)

const CertificateForm = lazy(() =>
  import('./Certificate/CertificateForm').then(module => ({
    default: module.CertificateForm,
  }))
)

const ReferencesListing = lazy(() =>
  import('./References/ReferencesListing').then(module => ({
    default: module.ReferencesListing,
  }))
)

const ReferencesForm = lazy(() =>
  import('./References/ReferencesForm').then(module => ({
    default: module.ReferencesForm,
  }))
)

const HealthInfoForm = lazy(() =>
  import('./HealthInfo/HealthInfoForm').then(module => ({
    default: module.HealthInfoForm,
  }))
)

const JobHistoryListing = lazy(() =>
  import('./JobHistory/JobHistoryListing').then(module => ({
    default: module.JobHistoryListing,
  }))
)

const JobHistoryForm = lazy(() =>
  import('./JobHistory/JobHistoryForm').then(module => ({
    default: module.JobHistoryForm,
  }))
)

const UserDefineForm = lazy(() =>
  import('./UserDefine/UserDefineForm').then(module => ({
    default: module.UserDefineForm,
  }))
)

const PersonalDocumentListing = lazy(() =>
  import('./PersonalDocument/PersonalDocumentList').then(module => ({
    default: module.PersonalDocumentListing,
  }))
)

const PersonalTP3Details = lazy(() =>
  import('./PersonalProfile/PersonalTP3/PersonalTP3Details').then(module => ({
    default: module.PersonalTP3Details,
  }))
)

const AssetAssignedHome = lazy(() =>
  import('./HomeAssetAssigned/HomeAsset').then(module => ({
    default: module.AssetAssignedHome,
  }))
)

const InsuranceSchemeHome = lazy(() =>
  import('./HomeInsuranceScheme/InsuranceScheme').then(module => ({
    default: module.InsuranceScheme,
  }))
)

const MyDutyRoster = lazy(() =>
  import('./TimeAttendance/MyDutyRoster/MyDutyRoster').then(module => ({
    default: module.MyDutyRoster,
  }))
)

const TodoCalendar = lazy(() =>
  import('./Todo/TodoCalendar').then(module => ({
    default: module.TodoCalendar,
  }))
)

const TodoLeaveView = lazy(() =>
  import('./Todo/TodoLeaveView').then(module => ({
    default: module.TodoLeaveView,
  }))
)

const TodoMenu = lazy(() =>
  import('./Todo/TodoMenu').then(module => ({
    default: module.TodoMenu,
  }))
)

const TodoSubKpi = lazy(() =>
  import('./Todo/TodoKPIPlanningApproval/SubordinateAppraisalListing').then(
    module => ({
      default: module.SubordinateAppraisalListing,
    })
  )
)

const TodoSubKpiApproval = lazy(() =>
  import('./Todo/TodoKPIPlanningApproval/SubordinateAppraisalApproval').then(
    module => ({
      default: module.SubordinateAppraisalApproval,
    })
  )
)

const TodoLeave = lazy(() =>
  import('./Todo/TodoLeave').then(module => ({
    default: module.TodoLeave,
  }))
)
const TodoClaim = lazy(() =>
  import('./Todo/TodoClaim').then(module => ({
    default: module.TodoClaim,
  }))
)
const TodoRequisition = lazy(() =>
  import('./Todo/TodoRequisition').then(module => ({
    default: module.TodoRequisition,
  }))
)
const TodoOverTime = lazy(() =>
  import('./Todo/TodoOverTime').then(module => ({
    default: module.TodoOverTime,
  }))
)
const TodoOverTimeDetails = lazy(() =>
  import('./Todo/TodoOvertimeDetails').then(module => ({
    default: module.TodoOvertimeDetails,
  }))
)
const TodoBatchOverTime = lazy(() =>
  import('./Todo/TodoBatchOvertimeEntry').then(module => ({
    default: module.TodoBatchOvertimeEntry,
  }))
)
const TodoBatchOverTimeEntryApprovalForm = lazy(() =>
  import('./Todo/TodoBatchOvertimeEntryApprovalForm').then(module => ({
    default: module.TodoBatchOvertimeEntryApprovalForm,
  }))
)
const TodoTimeOff = lazy(() =>
  import('./Todo/TodoTimeOff').then(module => ({
    default: module.TodoTimeOff,
  }))
)
const TodoChangeShift = lazy(() =>
  import('./Todo/TodoChangeShift').then(module => ({
    default: module.TodoChangeShift,
  }))
)
const TodoProjectExpense = lazy(() =>
  import('./Todo/TodoProjectExpense').then(module => ({
    default: module.TodoProjectExpense,
  }))
)
const ToDoDataChanges = lazy(() =>
  import('./Todo/TodoDataChanges').then(module => ({
    default: module.ToDoDataChanges,
  }))
)
const ToDoDataChangesDetails = lazy(() =>
  import('./Todo/TodoDataChangesDetails').then(module => ({
    default: module.ToDoDataChangesDetails,
  }))
)
const MyTimeSheetMenu = lazy(() =>
  import('../HomeModule/MyTimeSheet/MyTimeSheetMenu').then(module => ({
    default: module.MyTimeSheetMenu,
  }))
)
const ProjectTimeSheetListing = lazy(() =>
  import('../HomeModule/MyTimeSheet/ProjectTimeSheetListing').then(module => ({
    default: module.ProjectTimeSheetListing,
  }))
)
const ProjectTimeSheetEntrySubmission = lazy(() =>
  import('../HomeModule/MyTimeSheet/ProjectTimeSheetEntrySubmission').then(
    module => ({
      default: module.ProjectTimeSheetEntrySubmission,
    })
  )
)
const TimeSheetListing = lazy(() =>
  import('../HomeModule/MyTimeSheet/TimeSheetListing').then(module => ({
    default: module.TimeSheetListing,
  }))
)
const TimeSheetForm = lazy(() =>
  import('../HomeModule/MyTimeSheet/TimeSheetForm').then(module => ({
    default: module.TimeSheetForm,
  }))
)
const StaffVersion = lazy(() =>
  import('../HomeModule/MyKPI/StaffVersion').then(module => ({
    default: module.StaffVersion,
  }))
)
const StaffKpiForm = lazy(() =>
  import('../HomeModule/MyKPI/StaffKpiForm').then(module => ({
    default: module.StaffKpiForm,
  }))
)
const StaffKpiDetailForm = lazy(() =>
  import('../HomeModule/MyKPI/StaffKpiDetailForm').then(module => ({
    default: module.StaffKpiDetailForm,
  }))
)
const ScaleForm = lazy(() =>
  import('../HomeModule/MyKPI/ScaleForm').then(module => ({
    default: module.ScaleForm,
  }))
)
const SubordinateListing = lazy(() =>
  import('../HomeModule/MySubordinate/SubordinateListing').then(module => ({
    default: module.SubordinateListing,
  }))
)
const SubordinateHome = lazy(() =>
  import('./MySubordinate/SubordinateHome').then(module => ({
    default: module.SubordinateHome,
  }))
)
const TimeSheetSubordinateListing = lazy(() =>
  import(
    './MySubordinate/SubordinateModule/TimeSheet/TimeSheetSubordinateListing'
  ).then(module => ({
    default: module.TimeSheetSubordinateListing,
  }))
)

const ProjectExpenseListing = lazy(() =>
  import('./ProjectExpenseModule/ProjectExpenseListing').then(module => ({
    default: module.ProjectExpenseListing,
  }))
)

const SystemAdminCompanyInfoForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfo/CompanyInfoForm').then(module => ({
    default: module.CompanyInfoForm,
  }))
)

const CompanyInfoView = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfo/CompanyInfoView').then(module => ({
    default: module.CompanyInfoView,
  }))
)

const EmployeeAppSecurityListing = lazy(() =>
  import(
    './SystemAdmin/Company/EmployeeAppSecAssignment/EmployeeAppSecurityListing'
  ).then(module => ({
    default: module.EmployeeAppSecurityListing,
  }))
)

const EmployeeAppSecurityForm = lazy(() =>
  import(
    './SystemAdmin/Company/EmployeeAppSecAssignment/EmployeeAppSecurityForm'
  ).then(module => ({
    default: module.EmployeeAppSecurityForm,
  }))
)

const DepartmentListing = lazy(() =>
  import('./SystemAdmin/Company/Department/DepartmentListing').then(module => ({
    default: module.DepartmentListing,
  }))
)

const DivisionListing = lazy(() =>
  import('./SystemAdmin/Company/Department/DivisionListing').then(module => ({
    default: module.DivisionListing,
  }))
)

const ReportingStructureGroup = lazy(() =>
  import(
    './SystemAdmin/Company/ReportingStructure/ReportingStructureGroup'
  ).then(module => ({
    default: module.ReportingStructureGroup,
  }))
)

const ReportingStructure = lazy(() =>
  import('./SystemAdmin/Company/ReportingStructure/ReportingStructure').then(
    module => ({
      default: module.ReportingStructure,
    })
  )
)

const JobPositionListng = lazy(() =>
  import('./SystemAdmin/Company/ReportingStructure/JobPositionListing').then(
    module => ({
      default: module.JobPositionListng,
    })
  )
)

const StatutoryInfo = lazy(() =>
  import('./SystemAdmin/Company/StatutoryInfo/StatutoryInfo').then(module => ({
    default: module.StatutoryInfo,
  }))
)

const CompanyGeneralDocument = lazy(() =>
  import('./SystemAdmin/Company/GeneralDocument/GeneralDocument').then(
    module => ({
      default: module.GeneralDocument,
    })
  )
)

const RoleListing = lazy(() =>
  import('./SystemAdmin/Company/RoleAssignment/RoleListing').then(module => ({
    default: module.RoleListing,
  }))
)

const RoleAssignmentListing = lazy(() =>
  import('./SystemAdmin/Company/RoleAssignment/RoleAssignmentListing').then(
    module => ({
      default: module.RoleAssignmentListing,
    })
  )
)

const CompanyHierarchy = lazy(() =>
  import('./SystemAdmin/Company/CompanyHierarchy/CompanyHierarchy').then(
    module => ({
      default: module.CompanyHierarchy,
    })
  )
)

const OrgUnitStructure = lazy(() =>
  import('./SystemAdmin/Company/OrgUnitStructure/OrgUnitStructure').then(
    module => ({
      default: module.OrgUnitStructure,
    })
  )
)

const OrgUnitStructureForm = lazy(() =>
  import('./SystemAdmin/Company/OrgUnitStructure/OrgUnitStructureForm').then(
    module => ({
      default: module.OrgUnitStructureForm,
    })
  )
)

const JobPostionByOrgUnit = lazy(() =>
  import(
    './SystemAdmin/Company/JobPositionByOrgUnit/JobPositionByOrgUnit'
  ).then(module => ({
    default: module.JobPositionByOrgUnit,
  }))
)

const AccessPermissionRoleListing = lazy(() =>
  import(
    './SystemAdmin/Company/AccessPermission/AccessPermissionRoleListing'
  ).then(module => ({
    default: module.AccessPermissionRoleListing,
  }))
)

const AutoNumberingAssignment = lazy(() =>
  import(
    './SystemAdmin/Company/AutoNumberingAssignment/AutoNumberingAssignment'
  ).then(module => ({
    default: module.AutoNumberingAssignment,
  }))
)

const AutoNumberingAssignmentForm = lazy(() =>
  import(
    './SystemAdmin/Company/AutoNumberingAssignment/AutoNumberingAssignmentForm'
  ).then(module => ({
    default: module.AutoNumberingAssignmentForm,
  }))
)

const AccessPermission = lazy(() =>
  import('./SystemAdmin/Company/AccessPermission/AccessPermission').then(
    module => ({
      default: module.AccessPermission,
    })
  )
)

const SubordinateTreePersonalInfo = lazy(() =>
  import('./Subordinates/SubordinateTreePersonalInfo').then(module => ({
    default: module.SubordinateTreePersonalInfo,
  }))
)

const SubordinateTreeTimeAttendance = lazy(() =>
  import('./Subordinates/TimeAttendanceSubordinateTree').then(module => ({
    default: module.SubordinateTreeTimeAttendance,
  }))
)

const SubordinateTimeAttendanceMenu = lazy(() =>
  import('./Subordinates/TimeAttendance/SubordinateTimeAttendanceMenu').then(
    module => ({
      default: module.SubordinateTimeAttendanceMenu,
    })
  )
)

const SubordinateTimeAttendanceMainMenu = lazy(() =>
  import('./Subordinates/TimeAttendance/SubordinateTimeAttedanceMainMenu').then(
    module => ({
      default: module.SubordinateTimeAttendanceMainMenu,
    })
  )
)

const SubordinateDutyRoster = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateDutyRoster/SubordinateDutyRoster'
  ).then(module => ({
    default: module.SubordinateDutyRoster,
  }))
)

const SubordinateOvertimeListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeListing'
  ).then(module => ({
    default: module.SubordinateOvertimeListing,
  }))
)

const SubordinateOvertimeView = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeView'
  ).then(module => ({
    default: module.SubordinateOvertimeView,
  }))
)

const SubordinateOvertimeForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateOvertime/SubordinateOvertimeForm'
  ).then(module => ({
    default: module.SubordinateOvertimeForm,
  }))
)

const SubordinateTimeOffListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffListing'
  ).then(module => ({
    default: module.SubordinateTimeOffListing,
  }))
)

const SubordinateTimeOffForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffForm'
  ).then(module => ({
    default: module.SubordinateTimeOffForm,
  }))
)

const SubordinateTimeOffDetails = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateTimeOff/SubordinateTimeOffDetails'
  ).then(module => ({
    default: module.SubordinateTimeOffDetails,
  }))
)

const SubordinateChangeShiftRequestListing = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftListing'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestListing,
  }))
)

const SubordinateChangeShiftRequestDetails = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftDetails'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestDetails,
  }))
)

const SubordinateChangeShiftRequestForm = lazy(() =>
  import(
    './Subordinates/TimeAttendance/SubordinateChangeShift/SubordinateChangeShiftForm'
  ).then(module => ({
    default: module.SubordinateChangeShiftRequestForm,
  }))
)

const SubordinateAttendanceAnalysis = lazy(() =>
  import(
    './Subordinates/TimeAttendance/AttendanceRecords/SubordinateAttendanceAnalysis'
  ).then(module => ({
    default: module.SubordinateAttendanceAnalysis,
  }))
)

const SubordinateTreeEmployeeInfo = lazy(() =>
  import('./Subordinates/SubordinateTreeEmploymentInfo').then(module => ({
    default: module.SubordinateTreeEmploymentInfo,
  }))
)
const SubordinateAttendanceRecords = lazy(() =>
  import(
    './Subordinates/TimeAttendance/AttendanceRecords/SubordinateAttendanceRecords'
  ).then(module => ({
    default: module.SubordinateAttendanceRecords,
  }))
)

const SubordinateTreeTimesheet = lazy(() =>
  import('./Subordinates/SubordinateTreeTimesheet').then(module => ({
    default: module.SubordinateTreeTimesheet,
  }))
)

const SubordinateTreeLeave = lazy(() =>
  import('./Subordinates/SubordinateTreeLeave').then(module => ({
    default: module.SubordinateTreeLeave,
  }))
)

const SubordinateTreeClaim = lazy(() =>
  import('./Subordinates/SubordinateTreeClaim').then(module => ({
    default: module.SubordinateTreeClaim,
  }))
)

const SubordinateTreeProjectExpense = lazy(() =>
  import('./Subordinates/SubordinateTreeProjectExpense').then(module => ({
    default: module.SubordinateTreeProjectExpense,
  }))
)

const SubordinateEmployeeInfoNav = lazy(() =>
  import('./Subordinates/EmploymentInfo/EmploymentInfoNav').then(module => ({
    default: module.EmploymentInfoNav,
  }))
)

const SubordinatePersonnelInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/SubordinatePersonalNav'
  ).then(module => ({
    default: module.SubordinatePersonalNav,
  }))
)
const SubordinatePersonnelInfoForm = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalInfo/PersonalInfoDetails'
  ).then(module => ({
    default: module.PersonalInfoDetails,
  }))
)

const SubordinatePersonnelEducation = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/Education/EducationList'
  ).then(module => ({
    default: module.EducationList,
  }))
)

const SubordinatePersonnelCert = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/Certificate/CertificateListing'
  ).then(module => ({
    default: module.CertificateListing,
  }))
)

const SubordinatePersonnelRef = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/References/ReferencesListing'
  ).then(module => ({
    default: module.ReferencesListing,
  }))
)
const SubordinatePersonnelHealth = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/HealthInfo/HealthInfoForm'
  ).then(module => ({
    default: module.HealthInfoForm,
  }))
)

const SubordinatePersonnelDependent = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalDependent/PersonalDependentDetails'
  ).then(module => ({
    default: module.PersonalDependentDetails,
  }))
)
const SubordinatePersonnelJobHistory = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/JobHistory/JobHistoryListing'
  ).then(module => ({
    default: module.JobHistoryListing,
  }))
)

const SubordinatePersonnelAdditionalInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/UserDefine/UserDefineForm'
  ).then(module => ({
    default: module.UserDefineForm,
  }))
)

const SubordinatePersonnelDocInfo = lazy(() =>
  import(
    './Subordinates/SubordinatePersonalProfile/PersonalDocument/PersonalDocumentList'
  ).then(module => ({
    default: module.PersonalDocumentListing,
  }))
)

const SubordinateLeave = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeave').then(module => ({
    default: module.SubordinateLeave,
  }))
)

const SubordinateLeaveForm = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeaveForm').then(module => ({
    default: module.SubordinateLeaveForm,
  }))
)

const SubordinateLeaveDetail = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateLeaveDetail').then(module => ({
    default: module.SubordinateLeaveDetail,
  }))
)

const SubordinateEntitlementTable = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateEntitlementTable').then(
    module => ({
      default: module.SubordinateEntitlementTable,
    })
  )
)

const SubordinateReplacementLeaveTable = lazy(() =>
  import('./Subordinates/LeaveRecord/SubordinateReplacementLeaveTable').then(
    module => ({
      default: module.SubordinateReplacementLeaveTable,
    })
  )
)

const SubordinateClaim = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimListing').then(
    module => ({
      default: module.SubordinateClaimListing,
    })
  )
)

const SubordinateClaimForm = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimForm').then(module => ({
    default: module.SubordinateClaimForm,
  }))
)
const SubordinateClaimItemForm = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimItemForm').then(
    module => ({
      default: module.SubordinateClaimItemForm,
    })
  )
)
const SubordinateClaimDetail = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimDetail').then(module => ({
    default: module.SubordinateClaimDetail,
  }))
)

const SubordinateClaimItemDetail = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimItemDetail').then(
    module => ({
      default: module.SubordinateClaimItemDetail,
    })
  )
)

const SubordinateClaimEntitlementTable = lazy(() =>
  import('./Subordinates/ClaimRecords/SubordinateClaimEntitlementTable').then(
    module => ({
      default: module.SubordinateClaimEntitlementTable,
    })
  )
)

const SubordinateProjectExpense = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseListing'
  ).then(module => ({
    default: module.SubordinateProjectExpenseListing,
  }))
)

const SubordinateProjectExpenseForm = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseForm'
  ).then(module => ({
    default: module.SubordinateProjectExpenseForm,
  }))
)
const SubordinateProjectExpenseClaimItemForm = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseClaimItemForm'
  ).then(module => ({
    default: module.SubordinateProjectExpenseClaimItemForm,
  }))
)
const SubordinateProjectExpenseDetail = lazy(() =>
  import(
    './Subordinates/ProjectExpenseRecords/SubordinateProjectExpenseDetail'
  ).then(module => ({
    default: module.SubordinateProjectExpenseDetail,
  }))
)

const SubordinateRequisitionListing = lazy(() =>
  import(
    './Subordinates/RequisitionRecords/SubordinateRequisitionListing'
  ).then(module => ({
    default: module.SubordinateRequisitionListing,
  }))
)

const SubordinateRequisitionForm = lazy(() =>
  import('./Subordinates/RequisitionRecords/SubordinateRequisitionForm').then(
    module => ({
      default: module.SubordinateRequisitonForm,
    })
  )
)

const SubordinateRequisitionDetail = lazy(() =>
  import('./Subordinates/RequisitionRecords/SubordinateRequisitionDetail').then(
    module => ({
      default: module.SubordinateRequisitionDetail,
    })
  )
)

const DigitalBulletinListing = lazy(() =>
  import('./SystemAdmin/E-Bulletin/DigitalBulletinListing').then(module => ({
    default: module.DigitalBulletinListing,
  }))
)

const DigitalBulletinForm = lazy(() =>
  import('./SystemAdmin/E-Bulletin/DigitalBulletinForm').then(module => ({
    default: module.DigitalBulletinForm,
  }))
)

const AcknowledgedList = lazy(() =>
  import('./SystemAdmin/E-Bulletin/AcknowledgedList').then(module => ({
    default: module.AcknowledgedList,
  }))
)
const MyPayrollHome = lazy(() =>
  import('./MyPayrollInfo/MyPayrollHome').then(module => ({
    default: module.MyPayrollHome,
  }))
)
const PayrollHistoryMonthYear = lazy(() =>
  import('./MyPayrollInfo/ViewPayrollHistory/PayrollHistoryMonthYear').then(
    module => ({
      default: module.PayrollHistoryMonthYear,
    })
  )
)
const PayrollHistoryMonthView = lazy(() =>
  import('./MyPayrollInfo/ViewPayrollHistory/PayrollHistoryMonthView').then(
    module => ({
      default: module.PayrollHistoryMonthView,
    })
  )
)

const HomeProjectExpenseClaim = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimListing').then(
    module => ({
      default: module.MyProjectExpenseClaimListing,
    })
  )
)

const MyProjectExpenseClaimForm = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimForm').then(
    module => ({
      default: module.MyProjectExpenseClaimForm,
    })
  )
)

const MyProjectExpenseClaimDetail = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimDetail').then(
    module => ({
      default: module.MyProjectExpenseClaimDetail,
    })
  )
)

// const MyProjectExpenseClaimEntitlementTable = lazy(() =>
//   import(
//     './EmployeeMyProjectExpenseClaim/MyProjectExpenseClaimEntitlementTable'
//   ).then(module => ({
//     default: module.ProjectExpenseEntitlementTable,
//   }))
// )

const ProjectExpenseClaimItemForm = lazy(() =>
  import('./EmployeeMyProjectExpenseClaim/ProjectExpenseClaimItemForm').then(
    module => ({
      default: module.ProjectExpenseClaimItemForm,
    })
  )
)
// const TMSPolicyMatrixMenuList = lazy(() =>
//   import(
//     './TimeAttendanceManagement/CompanySetting/TMSPolicyMatrix/TMSPolicyMatrixMenuList'
//   ).then(module => ({
//     default: module.TMSPolicyMatrixMenuList,
//   }))
// )

const DataChangeRequestListing = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangeRequestListing').then(
    module => ({
      default: module.DataChangeRequestListing,
    })
  )
)

const DataImportListing = lazy(() =>
  import('../HomeModule/DataImport/DataImportListing').then(module => ({
    default: module.DataImportListing,
  }))
)

const DataImport = lazy(() =>
  import('../HomeModule/DataImport/DataImport').then(module => ({
    default: module.DataImport,
  }))
)

const UserActivation = lazy(() =>
  import('../HomeModule/DataImport/UserActivation').then(module => ({
    default: module.UserActivation,
  }))
)

const DataChangeRequestForm = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangeRequestForm').then(
    module => ({
      default: module.DataChangeRequestForm,
    })
  )
)

const DataChangePersonnelDetail = lazy(() =>
  import('../HomeModule/DataChangeRequest/DataChangePersonnelDetail').then(
    module => ({
      default: module.DataChangePersonnelDetail,
    })
  )
)

const DataChangeRequestDependentAddForm = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestDependentAddForm').then(
    module => ({
      default: module.DataChangeRequestDependentAddForm,
    })
  )
)

const DataChangeRequestDependentEditForm = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestDependentEditForm').then(
    module => ({
      default: module.DataChangeRequestDependentEditForm,
    })
  )
)

const DataChangeRequestView = lazy(() =>
  import('./DataChangeRequest/DataChangeRequestView').then(module => ({
    default: module.DataChangeRequestView,
  }))
)

const DataChangeRequestEducationAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEducation/DataChangeEducationAddForm'
  ).then(module => ({
    default: module.DataChangeRequestEducationAddForm,
  }))
)

const DataChangeRequestEducationEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEducation/DataChangeEducationEditForm'
  ).then(module => ({
    default: module.DataChangeEducationEditForm,
  }))
)

const DataChangeRequestCertificateAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeCertificate/DataChangeCertificationAddForm'
  ).then(module => ({
    default: module.DataChangeRequestCertificationAddForm,
  }))
)

const DataChangeRequestCertificateEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeCertificate/DataChangeCertificateEditForm'
  ).then(module => ({
    default: module.DataChangeCertificateEditForm,
  }))
)

const DataChangeRequestEmploymentHistoryAddForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEmploymentHistory/DataChangeEmploymentHistoryAddForm'
  ).then(module => ({
    default: module.DataChangeEmploymentHistoryAddForm,
  }))
)

const DataChangeRequestEmploymentHistoryEditForm = lazy(() =>
  import(
    './DataChangeRequest/DataChangeEmploymentHistory/DataChangeEmploymentHistoryEditForm'
  ).then(module => ({
    default: module.DataChangeEmploymentHistoryEditForm,
  }))
)

const ProjectExpenseClaimApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicy'
  ).then(module => ({
    default: module.ClaimApprovalPolicy,
  }))
)

const ProjectExpenseClaimApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm'
  ).then(module => ({
    default: module.ClaimApprovalPolicyForm,
  }))
)

const ImportDataSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupListing'
  ).then(module => ({
    default: module.ImportDataSetupListing,
  }))
)

const AttendanceAnalysisTemplate = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AttendanceAnalysisTemplate/AttendanceAnalysisTemplate'
  ).then(module => ({
    default: module.AttendanceAnalysisTemplate,
  }))
)

const ImportDataSetupForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm'
  ).then(module => ({
    default: module.ImportDataSetupForm,
  }))
)

const PeriodicDeductionPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyListing'
  ).then(module => ({
    default: module.PeriodicDeductionPolicyListing,
  }))
)

const PeriodicDeductionPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm'
  ).then(module => ({
    default: module.PeriodicDeductionPolicyForm,
  }))
)

const HelpdeskListing = lazy(() =>
  import('./HelpdeskModule/HelpdeskListing').then(module => ({
    default: module.HelpdeskListing,
  }))
)

const TodoHelpdesk = lazy(() =>
  import('./Todo/TodoHelpdesk').then(module => ({
    default: module.ToDoHelpdesk,
  }))
)

const Timesheet = lazy(() =>
  import('./SystemAdmin/GeneralSetting/CommonSettingRoute/Timesheet').then(
    module => ({
      default: module.Timesheet,
    })
  )
)

const ApproverRecordListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverRecordListing'
  ).then(module => ({
    default: module.ApproverRecordListing,
  }))
)

const ApproverAssignmentListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverAssignmentListing'
  ).then(module => ({
    default: module.ApproverAssignmentListing,
  }))
)

const ApproverAssignmentForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverAssignmentForm'
  ).then(module => ({
    default: module.ApproverAssignmentForm,
  }))
)

const ExternalSummaryTimesheet = lazy(() =>
  import('./MyTimeSheet/ExternalSummaryTimesheet').then(module => ({
    default: module.ExternalSummaryTimesheet,
  }))
)
// C:\Users\yvonnehaw\hr-root\packages\hr-employee-react\src\containers\HomeModule\SystemAdmin\ReportingStructureGroup\ReporitngStructureGroupListing.tsx
const ReportingStructureGroupListing = lazy(() =>
  import(
    './SystemAdmin/ReportingStructureGroup/ReportingStructureGroupListing'
  ).then(module => ({
    default: module.ReportingStructureGroupListing,
  }))
)

const MulticurrencySetupMenu = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencySetupMenu').then(
    module => ({
      default: module.MulticurrencySetupMenu,
    })
  )
)

const MulticurrencyListing = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencyListing').then(
    module => ({
      default: module.MulticurrencyListing,
    })
  )
)

const MultiCurrencyImport = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MultiCurrencyImport').then(
    module => ({
      default: module.MultiCurrencyImport,
    })
  )
)

const MultiCurrencyExcelValidation = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MultiCurrencyExcelValidation').then(
    module => ({
      default: module.MultiCurrencyExcelValidation,
    })
  )
)

const MulticurrencyForm = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencyForm').then(
    module => ({
      default: module.MulticurrencyForm,
    })
  )
)

const MyPayrollDocuments = lazy(() =>
  import('./MyPayrollDocuments/MyPayrollDocumentsMenu').then(module => ({
    default: module.MyPayrollDocumentsMenu,
  }))
)
const PayslipDocumentsListing = lazy(() =>
  import('./MyPayrollDocuments/PayslipDocuments/PayslipDocumentsListing').then(
    module => ({
      default: module.PayslipDocumentsListing,
    })
  )
)
const EAFormDocumentsListing = lazy(() =>
  import('./MyPayrollDocuments/EAFormDocuments/EAFormDocumentsListing').then(
    module => ({
      default: module.EAFormDocumentsListing,
    })
  )
)

const EmployeeUserDefineFieldsView = lazy(() =>
  import('./EmployeeUserDefineFields/EmployeeUserDefineFieldsView').then(
    module => ({
      default: module.EmployeeUserDefineFieldsView,
    })
  )
)

const TodoTraining = lazy(() =>
  import('./Todo/TodoTraining').then(module => ({
    default: module.TodoTraining,
  }))
)

const TodoTrainingDetails = lazy(() =>
  import('./Todo/TodoTrainingDetails').then(module => ({
    default: module.TodoTrainingDetails,
  }))
)

const OnBehalf = lazy(() =>
  import('./OnBehalf/OnBehalfMenu').then(module => ({
    default: module.OnBehalfMenu,
  }))
)

const OnBehalfListing = lazy(() =>
  import('./OnBehalf/OnBehalfListing').then(module => ({
    default: module.OnBehalfListing,
  }))
)

const TimeAttendanceMenu = lazy(() =>
  import('./OnBehalf/TimeAttendanceMenu').then(module => ({
    default: module.TimeAttendanceMenu,
  }))
)

const HomeRoutes = [
  //Project Expense Claim
  {
    props: { exact: true, path: '/home/myprojectexpenseclaim' },
    component: <HomeProjectExpenseClaim />,
  },
  {
    props: { exact: true, path: '/home/myprojectexpenseclaim/form/add' },
    component: <MyProjectExpenseClaimForm mode="add" />,
  },

  {
    props: {
      path:
        '/home/myprojectexpenseclaim/myprojectexpenseclaimform/:ProjectExpenseClaimHeaderID/edit',
    },
    component: <MyProjectExpenseClaimForm mode="edit" />,
  },
  {
    props: {
      path:
        '/home/myprojectexpenseclaim/myprojectexpenseclaimdetail/:ProjectExpenseClaimHeaderID',
    },
    component: <MyProjectExpenseClaimDetail />,
  },
  {
    props: {
      exact: true,
      path: '/home/myprojectexpenseclaim/ProjectExpenseClaimItemForm',
    },
    component: <ProjectExpenseClaimItemForm />,
  },

  {
    props: { exact: true, path: '/' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <Home />,
  },
  {
    props: { exact: true, path: '/MyMenu' },
    component: <MyMenu />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/SystemAdminListing' },
    component: <SystemAdminListing />,
  },
  {
    props: { exact: true, path: '/home/mypayrollinfo/mypayrollinfohome' },
    component: <MyPayrollHome />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrollinfo/viewpayrollhistory/payrollhistorymonthyear',
    },
    component: <PayrollHistoryMonthYear />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrollinfo/viewpayrollhistory/payrollhistorymonthview',
    },
    component: <PayrollHistoryMonthView />,
  },
  {
    props: { exact: true, path: '/datachangerequest/datachangerequestlisting' },
    component: <DataChangeRequestListing />,
  },
  {
    props: { exact: true, path: '/dataimportlisting' },
    component: <DataImportListing />,
  },
  {
    props: { exact: true, path: '/dataimportlisting/dataimport' },
    component: <DataImport />,
  },
  {
    props: { exact: true, path: '/dataimportlisting/useractivation' },
    component: <UserActivation />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestlisting/datachangerequestview',
    },
    component: <DataChangeRequestView />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestform/add',
    },
    component: <DataChangeRequestForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestform/edit',
    },
    component: <DataChangeRequestForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangepersonneldetail',
    },
    component: <DataChangePersonnelDetail />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestdependentaddform',
    },
    component: <DataChangeRequestDependentAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestdependenteditform/edit',
    },
    component: <DataChangeRequestDependentEditForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequesteducationaddform',
    },
    component: <DataChangeRequestEducationAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequesteducationeditform',
    },
    component: <DataChangeRequestEducationEditForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestcertificateaddform',
    },
    component: <DataChangeRequestCertificateAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestcertificateeditform',
    },
    component: <DataChangeRequestCertificateEditForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestemploymenthistoryaddform',
    },
    component: <DataChangeRequestEmploymentHistoryAddForm />,
  },
  {
    props: {
      exact: true,
      path: '/datachangerequest/datachangerequestemploymenthistoryeditform',
    },
    component: <DataChangeRequestEmploymentHistoryEditForm />,
  },

  {
    props: { exact: true, path: '/ProjectExpenseListing' },
    component: <ProjectExpenseListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing' },
    component: <UserListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/ResendEmailInvitation' },
    component: <ResendEmailInvitation />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/detail' },
    component: <UserDetail />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/assign' },
    component: <UserAccess />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/form/add' },
    component: <UserForm formMode="add" />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/form/edit' },
    component: <UserForm formMode="edit" />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/AccessLevel/AccessLevelListing' },
    component: <AccessLevelListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanyListing' },
    component: <CompanyListing />,
  },
  {
    props: { exact: true, path: '/ModuleCompanyListing' },
    component: <ModuleCompanyListing />,
  },

  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanyInfoForm' },
    component: <CompanyInfoForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanyInfoForm/CompanyAltAddressForm',
    },
    component: <CompanyAltAddressForm />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanySummary' },
    component: <CompanySummary />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AutoNumberingSetup/AutoNumberingSetupListing',
    },
    component: <AutoNumberingSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AutoNumberingSetup/AutoNumberingSetupForm',
    },
    component: <AutoNumberingSetupForm />,
  },

  {
    props: { exact: true, path: '/PayrollManagementListing' },
    component: <PayrollManagementListing />,
  },

  {
    props: { exact: true, path: '/DigitalReportingManagementListing' },
    component: <DigitalReportingManagementListing />,
  },
  {
    props: { exact: true, path: '/payroll/employee' },
    component: <PayrollManagementEmployeeListing />,
  },
  {
    props: { exact: true, path: '/payroll/monthlyremuneration' },
    component: <MonthlyRemunerationEmployeeListing />,
  },
  {
    props: { exact: true, path: '/payroll/employeesalarymenu' },
    component: <EmployeeSalaryMenu />,
  },
  {
    props: { exact: true, path: '/payroll/employeesalarylogslisting' },
    component: <EmployeeSalaryLogsListing />,
  },
  {
    props: { exact: true, path: '/payroll/employeeenquiry' },
    component: <EmployeeEnquiry />,
  },
  {
    props: { exact: true, path: '/payroll/employeeexcell' },
    component: <EmployeeExcell />,
  },
  {
    props: {
      exact: true,
      path: '/payroll/employeeexcell/employeevalidationexcell',
    },
    component: <EmployeeValidationExcell />,
  },
  {
    props: { exact: true, path: '/payroll/period' },
    component: <PayPeriodListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-Bulletin/DigitalBulletinListing',
    },
    component: <DigitalBulletinListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/E-Bulletin/DigitalBulletinForm' },
    component: <DigitalBulletinForm />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/E-Bulletin/AcknowledgedList' },
    component: <AcknowledgedList />,
  },

  // {
  //   props: { exact: true, path: '/staffconfirmation/staffconfirmationlisting' },
  //   component: <StaffConfirmationListing />,
  // },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/E-LetterTemplateListing',
    },
    component: <ELetterTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyListing',
    },
    component: <DataChangesApprovalPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm/Add',
    },
    component: <DataChangesApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm/Edit',
    },
    component: <DataChangesApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/DataChangesPolicy/DataChangesPolicyView',
    },
    component: <DataChangesPolicyView />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/DataChangesPolicy/DataChangesPolicyListing',
    },
    component: <DataChangesPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/DataChangesPolicy/DataChangesPolicyForm/Add',
    },
    component: <DataChangesPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/DataChangesPolicy/DataChangesPolicyForm/Edit/:id',
    },
    component: <DataChangesPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateView/:Category',
    },
    component: <ReportTemplateView />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateSetupListing/:Category',
    },
    component: <ReportTemplateSetupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/StandardELetteringTemplateListing/:Category',
    },
    component: <StandardELetteringTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category/new',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category/edit',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportForPreview/:Category',
    },
    component: <ReportForPreview />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Add',
    },
    component: <AccessLevelForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Detail/:id',
    },
    component: <AccessLevelDetail />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppListing',
    },
    component: <EmployeeAppListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppView',
    },
    component: <EmployeeAppView />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppForm/Add',
    },
    component: <EmployeeAppForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppForm/Edit/:id',
    },
    component: <EmployeeAppForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Edit/:id',
    },
    component: <AccessLevelForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Edit/:id',
    },
    component: <AccessLevelForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListing',
    },
    component: <GeneralSettingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2',
    },
    component: <GeneralSettingListingV2 />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PersonalManagement',
    },
    component: <PersonalManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PayrollManagement',
    },
    component: <PayrollManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/LeaveManagement',
    },
    component: <LeaveManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/ClaimManagement',
    },
    component: <ClaimManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/ProjectExpenseManagement',
    },
    component: <ProjectExpenseManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TimeAttendanceManagement',
    },
    component: <TimeAttendanceManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement',
    },
    component: <RequisitionManagement />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/Helpdesk',
    },
    component: <Helpdesk />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/Timesheet',
    },
    component: <Timesheet />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectListing',
    },
    component: <HelpdeskSubjectListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm/Add',
    },
    component: <HelpdeskSubjectForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm/Edit',
    },
    component: <HelpdeskSubjectForm mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectDetails',
    },
    component: <HelpdeskSubjectDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/PersonnelParameterListing',
    },
    component: <PersonnelParameterListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/SalutationListing',
    },
    component: <SalutationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/VaccinationTypeListing',
    },
    component: <VaccinationTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/ExitCompanyReasonListing',
    },
    component: <ExitCompanyReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/EducationLevelListing',
    },
    component: <EducationLevelListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/MajorListing',
    },
    component: <MajorListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/GradeListing',
    },
    component: <GradeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/StaffCategoryListing',
    },
    component: <StaffCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/SpecializationListing',
    },
    component: <SpecializationListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/IndustryListing',
    },
    component: <IndustryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/DocumentTypeListing',
    },
    component: <DocumentTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobGrade/JobGradeForm',
    },
    component: <JobGradeForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupListing',
    },
    component: <SkillSetSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm/Add',
    },
    component: <SkillSetSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm/:skillSetID/Edit',
    },
    component: <SkillSetSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionListing',
    },
    component: <JobPositionListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionForm/Add',
    },
    component: <JobPositionForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionForm/Edit',
    },
    component: <JobPositionForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/InsuranceSchemeSetup/InsuranceSchemeSetupListing',
    },
    component: <InsuranceSchemeSetupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/RecruitmentSource/RecruitmentSourceListing',
    },
    component: <RecruitmentSourceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineField/UserDefineFieldListing',
    },
    component: <UserDefineFieldListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelEFileSecurityPolicy/PersonnelEFileSecurityPolicyForm',
    },
    component: <PersonnelEFileSecurityPolicyForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayItem/PayItemListing',
    },
    component: <PayItemListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayItem/PayItemForm/:PayItemID',
    },
    component: <EnhancedPayrollItemForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyListing',
    },
    component: <AdvancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyForm',
    },
    component: <AdvancePolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LHDNReport/LHDNReportListing',
    },
    component: <LHDNReportListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LHDNReport/LHDNReportForm',
    },
    component: <LHDNReportForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GLAccountMapping/listing',
    },
    component: <PayrollGLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GLAccountMapping/listing/form',
    },
    component: <PayrollGLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineEPFPolicy/UserDefineEPFPolicyListing',
    },
    component: <UserDefineEPFPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineEPFPolicy/UserDefineEPFPolicyForm',
    },
    component: <UserDefineEPFPolicyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineEmployerEPFVEPolicy/UserDefineEmployerEPFVEPolicyListing',
    },
    component: <UserDefineEmployerEPFVEPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineEmployerEPFVEPolicy/UserDefineEmployerEPFVEPolicyForm',
    },
    component: <UserDefineEmployerEPFVEPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayGroup/PayGroupListing',
    },
    component: <PayGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleListing',
    },
    component: <PayrollCycleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleForm',
    },
    component: <PayrollCycleForm />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollCompanySetting',
    },
    component: <PayrollCompanySetting />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/TemplateLibraryAssignment/PayrollTemplateLibraryAssignment',
    },
    // component: <PayrollTemplateLibraryAssignment />,
    component: <EnhancedPayrollTemplateLibraryAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/PayrollSetting/PayrollSetting',
    },
    component: <PayrollSetting />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/SystemAdmin/GeneralSetting/PayrollCompanySetting/GLAccountMappingAssignment',
  //   },
  //   component: <PayrollGLMappingAssignment />,
  // },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/BankListing',
    },
    component: <BankListing />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/GLAccountMappingAssignmentForm',
    },
    component: <PayrollGLAccountMappingAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/BankForm',
    },
    component: <BankForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixListing',
    },
    component: <UserDefineEPFPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEPFPolicyMatrix/UserDefineEPFPolicyMatrixForm',
    },
    component: <UserDefineEPFPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixListing',
    },
    component: <UserDefineEmployerEPFVEPolicyMatrixListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/PayrollManagement/CompanySetting/UserDefineEmployerEPFVEPolicyMatrix/UserDefineEmployerEPFVEPolicyMatrixForm',
    },
    component: <UserDefineEmployerEPFVEPolicyMatrixForm />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/PayrollInitiation/InitiationPayCycle',
    },
    component: <PayrollInitiationPayCycle />,
  },
  {
    props: {
      exact: true,
      path: '/PayrollManagement/CompanySetting/Bank/InterBankListing',
    },
    component: <InterBankListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveReason/LeaveReasonListing',
    },
    component: <LeaveReasonListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/DiseaseCode/DiseaseCodeListing',
    },
    component: <DiseaseCodeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveType/LeaveTypeListing',
    },
    component: <LeaveTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyListing',
    },
    component: <LeavePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm/add',
    },
    component: <LeavePolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm/:leavePolicyID/edit',
    },
    component: <LeavePolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyDetail',
    },
    component: <LeavePolicyDetail />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingListing',
    },
    component: <LeaveRoundingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm/add',
    },
    component: <LeaveRoundingForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm/:leaveRoundingID/edit',
    },
    component: <LeaveRoundingForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingDetail',
    },
    component: <LeaveRoundingDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicy',
    },
    component: <LeaveApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm/add',
    },
    component: <LeaveApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm/edit',
    },
    component: <LeaveApprovalPolicyForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimParameterListing',
    },
    component: <ClaimParameterListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/VehicleType/VehicleTypeListing',
    },
    component: <VehicleTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing',
    },
    component: <ClaimUserDefineFieldsListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing/ClaimUserDeFineFieldsForm',
    },
    component: <ClaimUserDefineFieldsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageLocation/MileageLocationListing',
    },
    component: <MileageLocationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableListing',
    },
    component: <MileageTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm/Add',
    },
    component: <MileageTableForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm/Edit',
    },
    component: <MileageTableForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MealType/MealTypeListing',
    },
    component: <MealTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimParameters/Venue/VenueListing',
    },
    component: <VenueListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimType/ClaimTypeListing',
    },
    component: <ClaimTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyListing',
    },
    component: <ClaimPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm/Add',
    },
    component: <ClaimPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm/edit',
    },
    component: <ClaimPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDetail',
    },
    component: <ClaimPolicyDetail />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm/Add',
    },
    component: <ClaimPolicyDailyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm/Edit',
    },
    component: <ClaimPolicyDailyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm/Add',
    },
    component: <ClaimPolicyMealForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm/Edit',
    },
    component: <ClaimPolicyMealForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicy',
    },
    component: <ClaimApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm/add',
    },
    component: <ClaimApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm/edit',
    },
    component: <ClaimApprovalPolicyForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupListing',
    },
    component: <ShiftSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm/Add',
    },
    component: <ShiftSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm/Edit/:shiftID',
    },
    component: <ShiftSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternListing',
    },
    component: <ShiftPatternListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm/Add',
    },
    component: <ShiftPatternForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm/Edit/:shiftID',
    },
    component: <ShiftPatternForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupListing',
    },
    component: <ShiftGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm/Add',
    },
    component: <ShiftGroupForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm/Edit',
    },
    component: <ShiftGroupForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyListing',
    },
    component: <OvertimePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm/Add',
    },
    component: <OvertimePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm/Edit',
    },
    component: <OvertimePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableListing',
    },
    component: <OvertimeClaimTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm/Add',
    },
    component: <OvertimeClaimTableForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm/Edit',
    },
    component: <OvertimeClaimTableForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableView',
    },
    component: <OvertimeClaimTableView mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeReasonCodes/OvertimeReasonListing',
    },
    component: <OvertimeReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingListing',
    },
    component: <OvertimeRoundingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingPolicyForm/add',
    },
    component: <OvertimeRoundingPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingPolicyForm/edit/:OTRoundingID',
    },
    component: <OvertimeRoundingPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyListing',
    },
    component: <AllowanceDeductionRoundingPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Add',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Edit/:AllowanceDeductionID',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Edit/:AllowanceDeductionID',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyListing',
    },
    component: <DailyAllowancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm/Add',
    },
    component: <DailyAllowancePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm/Edit/:dailyID',
    },
    component: <DailyAllowancePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyListing',
    },
    component: <MonthlyAllowancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm/Add',
    },
    component: <MonthlyAllowancePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm/Edit/:monthlyID',
    },
    component: <MonthlyAllowancePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyListing',
    },
    component: <DeductionPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Add',
    },
    component: <DeductionPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Edit/:deductionID',
    },
    component: <DeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Edit/:deductionID',
    },
    component: <DeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyListing',
    },
    component: <TimeOffPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm/Add',
    },
    component: <TimeOffPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm/Edit/:TimeOffSettingID',
    },
    component: <TimeOffPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffReason/TimeOffReasonListing',
    },
    component: <TimeOffReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInListing',
    },
    component: <MobileCheckInListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceListing',
    },
    component: <BeaconDeviceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm/Add',
    },
    component: <BeaconDeviceForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm/Edit',
    },
    component: <BeaconDeviceForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/Add',
    },
    component: <MobileCheckInForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/View/:checkInID',
    },
    component: <GenerateQR />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/Edit/:checkInID',
    },
    component: <MobileCheckInForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSAPMenu',
    },
    component: <TMSAPMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/overtime',
    },
    component: <TMSApprovalPolicy type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/timeoff',
    },
    component: <TMSApprovalPolicy type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/changeshiftrequest',
    },
    component: <TMSApprovalPolicy type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/batchovertimeentry',
    },
    component: <TMSApprovalPolicy type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/overtime/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/timeoff/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/changeshiftrequest/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/batchovertimeentry/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/overtime/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/timeoff/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/changeshiftrequest/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/batchovertimeentry/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="BatchOvertimeEntry" />,
  },
  {
    props: { exact: true, path: '/home/mysubordinate/subordinatelisting' },
    component: <SubordinateListing />,
  },
  {
    props: { exact: true, path: '/subordinatehome' },
    component: <SubordinateHome />,
  },
  {
    props: { exact: true, path: '/subordinatehome/timesheetlisting' },
    component: <TimeSheetSubordinateListing />,
  },
  {
    props: { exact: true, path: '/home/myleave' },
    component: <NewMyLeave />,
  },

  {
    props: { exact: true, path: '/home/myleave/detail' },
    component: <MyLeaveDetail />,
  },

  // {
  //   props: { exact: true, path: '/home/myleave/myleaveform' },
  //   component: <HomeLeaveForm />,
  // },

  {
    props: { exact: true, path: '/home/myleave/myleaveform' },
    component: <NewMyLeaveForm />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/menu' },
    component: <MyTimeSheetMenu />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/projecttimesheetlisting' },
    component: <ProjectTimeSheetListing />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/projecttimesheet' },
    component: <ProjectTimeSheetEntrySubmission />,
  },
  {
    props: { exact: true, path: '/home/mytimesheet/timesheetlisting' },
    component: <TimeSheetListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mytimesheet/timesheetlisting/timesheetform',
    },
    component: <TimeSheetForm />,
  },

  {
    props: { path: '/home/myleave/myleaveform/edit' },
    component: <NewMyLeaveForm mode="edit" />,
  },

  {
    props: { exact: true, path: '/home/myleave/entitlementtable' },
    component: <EntitlementTable />,
  },

  {
    props: {
      exact: true,
      path: '/home/myleave/entitlementtable/replacementleavetable',
    },
    component: <ReplacementLeaveTable />,
  },

  //Claim
  {
    props: { exact: true, path: '/home/myclaim' },
    component: <HomeClaim />,
  },
  {
    props: { exact: true, path: '/home/myclaim/form/add' },
    component: <MyClaimForm mode="add" />,
  },
  {
    props: { path: '/home/myclaim/myclaimform/:ClaimHeaderID/edit' },
    component: <MyClaimForm mode="edit" />,
  },
  {
    props: { path: '/home/myclaim/myclaimdetail/:ClaimHeaderID' },
    component: <MyClaimDetail />,
  },
  {
    props: { path: '/home/myclaim/myclaimitemdetail' },
    component: <MyClaimItemDetail />,
  },
  {
    props: { exact: true, path: '/home/myclaim/myclaimentitlementtable' },
    component: <MyClaimEntitlementTable />,
  },

  {
    props: { exact: true, path: '/home/myclaim/ClaimItemForm' },
    component: <ClaimItemForm />,
  },

  //Requisition

  {
    props: { exact: true, path: '/home/myrequisition' },
    component: <HomeRequisition />,
  },

  {
    props: { exact: true, path: '/home/myrequisition/view' },
    component: <MyRequisitionDetail />,
  },

  {
    props: { exact: true, path: '/home/myrequisition/myrequisitionform/add' },
    component: <HomeRequisitionForm mode="add" />,
  },
  {
    props: {
      path: '/home/myrequisition/myrequistionform/:RequisitionHeaderID/edit',
    },
    component: <HomeRequisitionForm mode="edit" />,
  },

  //DigitalDocument
  {
    props: { exact: true, path: '/home/digitaldocument' },
    component: <HomeDigitalDocument />,
  },
  {
    props: { exact: true, path: '/home/digitaldocument/Documents' },
    component: <HomeDigitalDocuments />,
  },

  {
    props: { exact: true, path: '/Employee/doc/general' },
    component: <GeneralDocument />,
  },
  {
    props: { exact: true, path: '/Employee/doc/personal' },
    component: <PersonalDocument />,
  },
  {
    props: { exact: true, path: '/Employee/doc/payslip' },
    component: <PayslipListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eaform' },
    component: <EAFormListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eletter' },
    component: <ELetterListing />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eletter/eletterlist' },
    component: <ELetterView />,
  },
  {
    props: { exact: true, path: '/Employee/doc/eaform/eaformlist' },
    component: <EAFormView />,
  },
  {
    props: { exact: true, path: '/Employee/doc/payslip/paysliplist' },
    component: <PayslipView />,
  },

  //ToDoo
  // {
  //   props: { exact: true, path: '/home/todo' },
  //   component: <HomeTodo />,
  // },

  {
    props: { exact: true, path: '/home/todo' },
    component: <TodoMenu />,
  },
  {
    props: { exact: true, path: '/home/todo/claim' },
    component: <TodoClaim />,
  },
  {
    props: { exact: true, path: '/home/todo/projectexpense' },
    component: <TodoProjectExpense />,
  },
  {
    props: { exact: true, path: '/home/todo/leave' },
    component: <TodoLeave />,
  },
  {
    props: { exact: true, path: '/home/todo/requisition' },
    component: <TodoRequisition />,
  },
  {
    props: { exact: true, path: '/home/todo/overtime' },
    component: <TodoOverTime />,
  },
  {
    props: { exact: true, path: '/home/todo/overtime/details' },
    component: <TodoOverTimeDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/batchovertimeentry' },
    component: <TodoBatchOverTime />,
  },
  {
    props: { exact: true, path: '/home/todo/batchovertimeentry/approval' },
    component: <TodoBatchOverTimeEntryApprovalForm />,
  },
  {
    props: { exact: true, path: '/home/todo/timeoff' },
    component: <TodoTimeOff />,
  },
  {
    props: { exact: true, path: '/home/todo/changeshift' },
    component: <TodoChangeShift />,
  },
  {
    props: { exact: true, path: '/home/todo/subkpilisting' },
    component: <TodoSubKpi />,
  },
  {
    props: { exact: true, path: '/home/todo/subkpilisting/approval' },
    component: <TodoSubKpiApproval />,
  },

  {
    props: { path: '/home/todo/approvalhistory' },
    component: <ApprovalHistory />,
  },
  {
    props: { exact: true, path: '/home/todo/tododetail' },
    component: <TodoDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/todoprojectexpensedetail' },
    component: <TodoProjectExpenseDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/claim/detail' },
    component: <TodoClaimDetail />,
  },
  {
    props: { exact: true, path: '/home/todo/requisition/detail' },
    component: <TodoRequisitionDetailV2 />,
  },
  {
    props: { exact: true, path: '/home/todo/datachanges' },
    component: <ToDoDataChanges />,
  },
  {
    props: { exact: true, path: '/home/todo/datachanges/details' },
    component: <ToDoDataChangesDetails />,
  },
  {
    props: { exact: true, path: '/home/todo/helpdeskticket' },
    component: <TodoHelpdesk />,
  },
  {
    props: { exact: true, path: '/home/todo/training/request' },
    component: <TodoTraining type="Request" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/selfregistration' },
    component: <TodoTraining type="Self-Registration" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/nomination' },
    component: <TodoTraining type="Nomination" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/withdrawal' },
    component: <TodoTraining type="Withdrawal" />,
  },
  {
    props: { exact: true, path: '/home/todo/training/details' },
    component: <TodoTrainingDetails />,
  },
  //Notification
  {
    props: { exact: true, path: '/home/notification' },
    component: <HomeNotification />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDetail' },
    component: <HomeNotificationDetail />,
  },
  {
    props: { exact: true, path: '/home/HomeNotificationDataChangesDetail' },
    component: <HomeNotificationDataChangesDetail />,
  },

  //Subordinates
  {
    props: { exact: true, path: '/home/mysubordinates/orgchart' },
    component: <MySubordinatesListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary',
    },
    component: <MySubordinatesSummary />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummaryconcise',
    },
    component: <MySubordinatesSummaryOlderVersion />,
  },
  {
    props: {
      exact: true,
      path: '/mysubordinates',
    },
    component: <MySubordinatesSummary />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeeinfo',
    },
    component: <EmployeeInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeeinfo/details',
    },
    component: <EmployeeInfoDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/assetassigned',
    },
    component: <AssetAssignedDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords',
    },
    component: <LeaveRecordsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetails',
    },
    component: <NewLeaveRecordDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetailsform',
    },
    component: <NewLeaveRecordDetailsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleaverecorddetailsform/edit',
    },
    component: <NewLeaveRecordDetailsForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/entitlementleavetable',
    },
    component: <EntitlementLeaveTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/newleavesubdetail',
    },
    component: <NewLeaveSubDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaverecords/replacementleavesubtable',
    },
    component: <ReplacementLeaveSubTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimrecords',
    },
    component: <ClaimRecordsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/careerprogression',
    },
    component: <CareerProgressionDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionrecords',
    },
    component: <SubordinateRequisitionDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/subordinates',
    },
    component: <EmployeeSubordinates />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/employeejobinfodetails',
    },
    component: <EmployeeJobInfoDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/Subordinates/SubordinatePersonalProfile/PersonalStatInfo/PersonalStatDetails',
    },
    component: <SubordinatePersonalStatutoryDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/Subordinates/SubordinatePersonalProfile/PersonalTP3/PersonalTP3Details',
    },
    component: <SubordinatePersonalTP3Details />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/personalinfo',
    },
    component: <SubordinateTreePersonalInfo />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance',
    },
    component: <SubordinateTreeTimeAttendance />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/menu',
    },
    component: <SubordinateTimeAttendanceMenu />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/mainmenu',
    },
    component: <SubordinateTimeAttendanceMainMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentrylisting',
    },
    component: <MySubordinatesBatchOvertimeEntryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentry/add',
    },
    component: <MySubordinatesBatchOvertimeEntryForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/batchentry/view',
    },
    component: <MySubordinatesBatchOvertimeEntryView />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/dutyroster',
    },
    component: <SubordinateDutyRoster />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/overtimelisting',
    },
    component: <SubordinateOvertimeListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/overtimeview',
    },
    component: <SubordinateOvertimeView />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/overtimeform',
    },
    component: <SubordinateOvertimeForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/overtimeform/edit',
    },
    component: <SubordinateOvertimeForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeofflisting',
    },
    component: <SubordinateTimeOffListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/timeoffform',
    },
    component: <SubordinateTimeOffForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeoffform/edit',
    },
    component: <SubordinateTimeOffForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/timeoffdetails',
    },
    component: <SubordinateTimeOffDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftlisting',
    },
    component: <SubordinateChangeShiftRequestListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftdetails',
    },
    component: <SubordinateChangeShiftRequestDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftform',
    },
    component: <SubordinateChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/subordinatelist/timeattendance/changeshiftform/edit',
    },
    component: <SubordinateChangeShiftRequestForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendance/attendance',
    },
    component: <SubordinateAttendanceAnalysis />,
  },
  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timeattendancerecords',
    },
    component: <SubordinateAttendanceRecords />,
  },

  //CareerProgression

  {
    props: {
      exact: true,
      path: '/home/mycareerprogression',
    },
    component: <CareerProgression />,
  },

  //Bulletin
  {
    props: {
      exact: true,
      path: '/home/bulletin',
    },
    component: <HomeBulletin />,
  },

  //Calendar

  {
    props: {
      exact: true,
      path: '/home/calendar',
    },
    component: <HomeCalendar />,
  },

  //Personnel Profile
  {
    props: { exact: true, path: '/home/personnelprofile' },
    component: <PersonalNav />,
  },
  {
    props: { exact: true, path: '/home/EmploymentInfo' },
    component: <EmploymentInfoNav />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/Diary',
    },
    component: <DiaryListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/Diary/Form',
    },
    component: <DiaryForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalinfo/details',
    },
    component: <PersonalInfoDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalinfo/details/edit',
    },
    component: <PersonalInfoDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaljobinfo/details',
    },
    component: <PersonalJobDetails />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personalstatinfo/details',
    },
    component: <PersonalStatDetails />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/details',
    },
    component: <PersonalDependentDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/personaldependentview',
    },
    component: <PersonalDependentView />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaldependent/add',
    },
    component: <DependentForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Education/List',
    },
    component: <EducationList />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Education/Form',
    },
    component: <EducationForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Certificate/List',
    },
    component: <CertificateListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/Certificate/Form',
    },
    component: <CertificateForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/References/List',
    },
    component: <ReferencesListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/References/Form',
    },
    component: <ReferencesForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/HealthInfo/Form',
    },
    component: <HealthInfoForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/JobHistory/List',
    },
    component: <JobHistoryListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/JobHistory/Form',
    },
    component: <JobHistoryForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/UserDefine/Form',
    },
    component: <UserDefineForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/PersonalDocument/List',
    },
    component: <PersonalDocumentListing />,
  },

  {
    props: {
      exact: true,
      path: '/home/personnelprofile/personaltp3/details',
    },
    component: <PersonalTP3Details />,
  },

  //Home Asset Assigned
  {
    props: {
      exact: true,
      path: '/home/homeassetassigned/homeasset',
    },
    component: <AssetAssignedHome />,
  },

  //Home Insurance Scheme
  {
    props: {
      exact: true,
      path: '/home/homeinsurancescheme/insurancescheme',
    },
    component: <InsuranceSchemeHome />,
  },

  // Time Attendance

  {
    props: {
      exact: true,
      path: '/home/timeattendance',
    },
    component: <TimeAttendanceView />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtimelist',
    },
    component: <OvertimeListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtimedetails',
    },
    component: <OvertimeDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtime-application',
    },
    component: <OvertimeForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/overtime-application/edit',
    },
    component: <OvertimeForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/attendance',
    },
    component: <EmpAttendance />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/timeofflist',
    },
    component: <TimeOffListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoffdetails',
    },
    component: <TimeOffDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoff-application',
    },
    component: <TimeOffForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/timeoff-application/edit',
    },
    component: <TimeOffForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn',
    },
    component: <EmpCheckIn />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkOut',
    },
    component: <EmpCheckOut />,
  },
  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn/history',
    },
    component: <CheckInHistory />,
  },

  //// Delegation ////

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationListing',
    },
    component: <DelegationListing />,
  },

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationForm',
    },
    component: <DelegationForm />,
  },

  {
    props: {
      exact: true,
      path: '/Delegation/DelegationView',
    },
    component: <DelegationView />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestList',
    },
    component: <ChangeShiftRequestListing />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestForm',
    },
    component: <ChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestForm/edit',
    },
    component: <ChangeShiftRequestForm />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/changeshiftrequestdetails',
    },
    component: <ChangeShiftRequestDetails />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/checkIn/history',
    },
    component: <CheckInHistory />,
  },

  {
    props: {
      exact: true,
      path: '/Employee/tms/mydutyRoster',
    },
    component: <MyDutyRoster />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/calendar',
    },
    component: <TodoCalendar />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/LeaveView',
    },
    component: <TodoLeaveView />,
  },

  {
    props: {
      exact: true,
      path: '/home/todo/OvertimeView',
    },
    component: <TodoOvertimeDetails />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion',
    },
    component: <StaffVersion />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion/staffkpiform',
    },
    component: <StaffKpiForm />,
  },
  {
    props: {
      exact: true,
      path: '/home/mykpi/staffversion/staffkpiform/staffkpidetailform',
    },
    component: <StaffKpiDetailForm />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/glaccountmappinglisting',
    },
    component: <GLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/ProjectMaster',
    },
    component: <ProjectMasterListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/ProjectExpenseType',
    },
    component: <ProjectExpenseTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/glaccountmappinglisting/form',
    },
    component: <GLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mykpi/staffversion/staffkpiform/staffkpidetailform/scaleform',
    },
    component: <ScaleForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy',
    },
    component: <CommonSettingRequisitionAPMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitiontypes',
    },
    component: <CommonSettingRequisitionTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset',
    },
    component: <RequisitionApprovalPolicyList type="Asset" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash',
    },
    component: <RequisitionApprovalPolicyList type="Cash" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others',
    },
    component: <RequisitionApprovalPolicyList type="Others" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/travel',
    },
    component: <RequisitionApprovalPolicyList type="Travel" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovementrequest',
    },
    component: <RequisitionApprovalPolicyList type="Staff Movement" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/detail',
    },
    component: <RequisitionApprovalPolicyDetail />,
  },

  //Add

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Asset" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Cash" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Others" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/travel/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Travel" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovement/add',
    },
    component: (
      <RequisitionApprovalPolicyForm mode="add" type="Staff Movement" />
    ),
  },

  /// EDIT

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Asset" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Cash" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Others" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/Requisitionapprovalpolicy/travel/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Travel" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovement/edit',
    },
    component: (
      <RequisitionApprovalPolicyForm mode="edit" type="Staff Movement" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/ratingscalelisting',
    },
    component: <RatingScaleListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/ratingscalelisting/form',
    },
    component: <RatingScaleForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/finalratingtablelisting',
    },
    component: <FinalRatingTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/finalratingtablelisting/form',
    },
    component: <FinalRatingTableForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/jobskillcategorylisting',
    },
    component: <JobSkillCategoryListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting',
    },
    component: <CompetencyItemsListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting/competencyitemsdetails',
    },
    component: <CompetencyItemsDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting/competencyitemsdetails/competencyitemsform',
    },
    component: <CompetencyItemsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/otherappraisalitemslisting',
    },
    component: <OtherAppraisalItemsListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/otherappraisalitemslisting/otherappraisalitemsform',
    },
    component: <OtherAppraisalItemsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting',
    },
    component: <KpiAppraisalTemplateListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting/kpiappraisaltemplatedetails',
    },
    component: <KpiAppraisalTemplateDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting/kpiappraisaltemplatedetails/kpiappraisaltemplateforms',
    },
    component: <KpiAppraisalTemplateForms />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting',
    },
    component: <SkillsAppraisalTemplateListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting/skillsappraisaltemplatedetails',
    },
    component: <SkillsAppraisalTemplateDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting/skillsappraisaltemplatedetails/skillsappraisaltemplateforms',
    },
    component: <SkillsAppraisalTemplateForms />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/othersappraisaltemplatelisting',
    },
    component: <OthersAppraisalTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/othersappraisaltemplatelisting/othersappraisaltemplatedetails',
    },
    component: <OthersAppraisalTemplateDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/scaleratinglisting',
    },
    component: <ScaleRatingListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/scaleratinglisting/scaleratingform',
    },
    component: <ScaleRatingForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyinfoform',
    },
    component: <SystemAdminCompanyInfoForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyinfoview',
    },
    component: <CompanyInfoView />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/EmployeeApp/employeeappseclist',
    },
    component: <EmployeeAppSecurityListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/EmployeeApp/employeeappsecform',
    },
    component: <EmployeeAppSecurityForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/department',
    },
    component: <DepartmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/department/division',
    },
    component: <DivisionListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/reportingstructure',
    },
    component: <ReportingStructureGroup />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/reportingstructure/form',
    },
    component: <ReportingStructure />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/Company/CompanySummary/reportingstructure/form/jobposition',
    },
    component: <JobPositionListng />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/statutoryinfo',
    },
    component: <StatutoryInfo />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/generaldocument',
    },
    component: <CompanyGeneralDocument />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/rolelisting',
    },
    component: <RoleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/roleassignmentlisting',
    },
    component: <RoleAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyhierarchy',
    },
    component: <CompanyHierarchy />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/orgunitstructure',
    },
    component: <OrgUnitStructure />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/Company/CompanySummary/orgunitstructure/orgunitstructureform',
    },
    component: <OrgUnitStructureForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/jobpositionbyorgunit',
    },
    component: <JobPostionByOrgUnit />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/accesspermissionrolelist',
    },
    component: <AccessPermissionRoleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/accesspermission',
    },
    component: <AccessPermission />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/autonumberingassignment',
    },
    component: <AutoNumberingAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/autonumberingassignment/form',
    },
    component: <AutoNumberingAssignmentForm />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/employmentinfo',
    },
    component: <SubordinateTreeEmployeeInfo />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/timesheet',
    },
    component: <SubordinateTreeTimesheet />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/leave',
    },
    component: <SubordinateTreeLeave />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/claim',
    },
    component: <SubordinateTreeClaim />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/projectexpense',
    },
    component: <SubordinateTreeProjectExpense />,
  },

  {
    props: {
      exact: true,
      path: '/home/mysubordinates/subordinatelist/employmentinfonav',
    },
    component: <SubordinateEmployeeInfoNav />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav',
    },
    component: <SubordinatePersonnelInfo />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/personalinfoform',
    },
    component: <SubordinatePersonnelInfoForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/educationlist',
    },
    component: <SubordinatePersonnelEducation />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/certificatelist',
    },
    component: <SubordinatePersonnelCert />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/references',
    },
    component: <SubordinatePersonnelRef />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/healthinfo',
    },
    component: <SubordinatePersonnelHealth />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/dependentinfo',
    },
    component: <SubordinatePersonnelDependent />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/jobhistory',
    },
    component: <SubordinatePersonnelJobHistory />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/otherinfo',
    },
    component: <SubordinatePersonnelAdditionalInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/personalinfonav/docinfo',
    },
    component: <SubordinatePersonnelDocInfo />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leave',
    },
    component: <SubordinateLeave />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leavedetail',
    },
    component: <SubordinateLeaveDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/leaveform',
    },
    component: <SubordinateLeaveForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/Entitlement',
    },
    component: <SubordinateEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/ReplacementLeave',
    },
    component: <SubordinateReplacementLeaveTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claim',
    },
    component: <SubordinateClaim />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path:
  //       '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimdetail',
  //   },
  //   component: <SubordinateClaimDetail />,
  // },
  {
    props: {
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimdetail/:ClaimHeaderID',
    },
    component: <SubordinateClaimDetail />,
  },
  {
    props: {
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimitemdetail',
    },
    component: <SubordinateClaimItemDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimform',
    },
    component: <SubordinateClaimForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/claimform/claimItem',
    },
    component: <SubordinateClaimItemForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/ClaimEntitlement',
    },
    component: <SubordinateClaimEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpense',
    },
    component: <SubordinateProjectExpense />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpenseform',
    },
    component: <SubordinateProjectExpenseForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpenseform/claimItem',
    },
    component: <SubordinateProjectExpenseClaimItemForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/projectexpensedetail',
    },
    component: <SubordinateProjectExpenseDetail />,
  },

  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionListing',
    },
    component: <SubordinateRequisitionListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionForm',
    },
    component: <SubordinateRequisitionForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/home/mysubordinates/mysubordinateslisting/mysubordinatessummary/requisitionrecords',
    },
    component: <SubordinateRequisitionDetail />,
  },

  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome',
    },
    component: <MyPayrollDocuments />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome/payslipdocuments',
    },
    component: <PayslipDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/mypayrolldocuments/mypayrolldocumentshome/eaformdocuments',
    },
    component: <EAFormDocumentsListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/listing',
    },
    component: <ProjectExpenseGLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/listing/form',
    },
    component: <ProjectExpenseGLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicy',
    },
    component: <ProjectExpenseClaimApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm/add',
    },
    component: <ProjectExpenseClaimApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm/edit',
    },
    component: <ProjectExpenseClaimApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupListing',
    },
    component: <ImportDataSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AttendanceAnalysisTemplate',
    },
    component: <AttendanceAnalysisTemplate />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm',
    },
    component: <ImportDataSetupForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm/edit',
    },
    component: <ImportDataSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyListing',
    },
    component: <PeriodicDeductionPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm/Add',
    },
    component: <PeriodicDeductionPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm/Edit/:PeriodicDeductionID',
    },
    component: <PeriodicDeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/HelpdeskListing',
    },
    component: <HelpdeskListing />,
  },
  //Project Timesheet
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverRecordListing',
    },
    component: <ApproverRecordListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverAssignmentListing',
    },
    component: <ApproverAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverAssignmentForm',
    },
    component: <ApproverAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path: '/ProjectTimesheet/Approver/:token',
    },
    component: <ExternalSummaryTimesheet />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/ReporitngStructureGroup',
    },
    component: <ReportingStructureGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/home/EmploymentInfo/EmployeeUserDefineFieldsView',
    },
    component: <EmployeeUserDefineFieldsView />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencySetup',
    },
    component: <MulticurrencySetupMenu />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencyListing',
    },
    component: <MulticurrencyListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MultiCurrencyImport',
    },
    component: <MultiCurrencyImport />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MultiCurrencyExcelValidation',
    },
    component: <MultiCurrencyExcelValidation />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencyForm',
    },
    component: <MulticurrencyForm />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf',
    },
    component: <OnBehalf />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/OnBehalfListing',
    },
    component: <OnBehalfListing />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveListing',
    },
    component: <SubordinateLeave />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveListing/Entitlement',
    },
    component: <SubordinateEntitlementTable />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveDetail',
    },
    component: <SubordinateLeaveDetail />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Leave/LeaveForm',
    },
    component: <SubordinateLeaveForm />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendanceMenu',
    },
    component: <TimeAttendanceMenu />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/Claim/ClaimListing',
    },
    component: <SubordinateClaim />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendance/OvertimeListing',
    },
    component: <SubordinateOvertimeListing />,
  },
  {
    props: {
      exact: true,
      path: '/OnBehalf/TimeAttendance/TimeOffListing',
    },
    component: <SubordinateTimeOffListing />,
  },
]

export default HomeRoutes
