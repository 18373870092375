// import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import authenticationRoutes from 'containers/AccountModule/AccountRoutes'
import dashboardRoutes from 'containers/DashboardModule/DashboardRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'
import logOutRoutes from 'containers/LogOutModule/LogOutRoutes'
import HomeRoutes from 'containers/HomeModule/HomeRoutes'
import PayrollManagementRoutes from 'containers/HomeModule/PayrollManagement/PayrollManagementRoutes'

import DigitalReportingManagementRoutes from 'containers/HomeModule/DigitalReportingManagement/DigitalReportingManagementRoutes'
import CompanySummaryRoutes from 'containers/HomeModule/SystemAdmin/Company/CompanySummaryRoutes'
import ProjectExpenseRoutes from 'containers/HomeModule/ProjectExpenseModule/ProjectExpenseRoutes'
import ShopeeRoutes from 'containers/HomeModule/ShopeeImport/ShopeeImportRoutes'
import HelpdeskRoutes from 'containers/HomeModule/HelpdeskModule/HelpdeskRoutes'

const StartRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...HomeRoutes,
  ...dashboardRoutes,
  ...PayrollManagementRoutes,

  ...CompanySummaryRoutes,
  ...DigitalReportingManagementRoutes,

  ...ProjectExpenseRoutes,
  ...HelpdeskRoutes,
  ...ShopeeRoutes,
  ...logOutRoutes,
  ...authenticationRoutes,
  ...StartRoute,
  ...errorRoute,
]

export default routes
